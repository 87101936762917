import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

const initDayJs = () => {
	dayjs.extend(relativeTime);
	dayjs.extend(utc);
	dayjs.extend(duration);
	dayjs.extend(isToday);
	dayjs.extend(minMax);
	dayjs.extend(customParseFormat);
	dayjs.extend(advancedFormat);
	dayjs.extend(isSameOrBefore);
};

export default initDayJs;
