import axios, { AxiosResponse } from 'axios';
import { getBaseApiUrl, onError, refreshAndAddToken } from '../api/api';
import { DisplayPosition } from '../../constants/Common';

const US_DOLLAR_CODE = 'USD';

const paymentClient = axios.create();

paymentClient.interceptors.request.use(refreshAndAddToken, onError);

const getPaymentApiUrl = (suffix: string): string => `${getBaseApiUrl()}/payment/${suffix}`;

export const MINIMAL_CAST_CREDITS_PURCHASE_COUNT = 200;
export const DEFAULT_CAST_CREDITS_PURCHASE_COUNT = 500;

export interface PaymentPackage {
	units: number;
	bonus: number;
	currency: string;
}

export interface PaymentConfig {
	paymentCurrency: string;
	paymentCurrencySymbol: string;
	paymentCurrencySymbolDisplayPosition: DisplayPosition;
	castCurrency: string;
	rate: number;
	packages: PaymentPackage[];
}

interface PaymentCreateConfig {
	paymentId: number;
	clientSecret: string;
}

export const fetchPaymentConfig = async (country: string) => {
	const res = await paymentClient.get<AxiosResponse<PaymentConfig>>(getPaymentApiUrl(`config?country=${country}`));
	return res.data.data;
};

export const createPayment = async (amount: number, currency: string, casts: number) => {
	const res = await paymentClient.post<AxiosResponse<PaymentCreateConfig>>(getPaymentApiUrl('create'), {
		amount,
		currency,
		casts
	});
	return res.data;
};

export const getConvertedPrice = (totalCastCredits: number, rate: number): number => {
	//because 100 cast credits = 1GBP
	const castCreditsRatio = 100;
	const rateMultiplier = 1_000_000;
	return (totalCastCredits * rate) / rateMultiplier / castCreditsRatio;
};

export const getVisiblePrice = (paymentConfig: PaymentConfig, totalCastCredits: number): string => {
	const symbol = paymentConfig.paymentCurrencySymbol || paymentConfig.paymentCurrency;
	const price = getConvertedPrice(totalCastCredits, paymentConfig.rate);
	const roundedPrice = Number.isInteger(price) ? price : price.toFixed(2);

	if (paymentConfig.paymentCurrency === US_DOLLAR_CODE) {
		return `${symbol}${roundedPrice}\u00A0${paymentConfig.paymentCurrency}`;
	}

	return paymentConfig.paymentCurrencySymbolDisplayPosition === DisplayPosition.LEFT
		? `${symbol}${roundedPrice}`
		: `${roundedPrice}${symbol}`;
};

export const getVisiblePriceBonus = (paymentConfig: PaymentConfig): string => {
	const symbol = paymentConfig.paymentCurrencySymbol || paymentConfig.paymentCurrency;

	return paymentConfig.paymentCurrencySymbolDisplayPosition === DisplayPosition.LEFT ? `${symbol}0` : `0${symbol}`;
};

export const getApplicablePackage = (
	paymentConfig: PaymentConfig,
	totalCastCredits: number
): PaymentPackage | undefined => {
	return paymentConfig.packages.sort((a, b) => (a.units > b.units ? -1 : 1)).find(p => p.units <= totalCastCredits);
};

export const getVisibleBonus = (paymentConfig: PaymentConfig, totalCastCredits: number): number => {
	const applicablePackage = getApplicablePackage(paymentConfig, totalCastCredits);
	return applicablePackage?.bonus ? applicablePackage.bonus : 0;
};

export const calculateTotalCastWithBonus = (bonus: number, totalCastCredits: number): number => {
	return bonus + totalCastCredits;
};

export const getVisibleCasts = (paymentConfig: PaymentConfig, totalCastCredits: number): number => {
	const applicablePackage = getApplicablePackage(paymentConfig, totalCastCredits);
	return applicablePackage?.bonus
		? calculateTotalCastWithBonus(applicablePackage.bonus, totalCastCredits)
		: totalCastCredits;
};

export const getCurrencySubunit = (value: number) => {
	return +(value * 100).toFixed(0);
};
