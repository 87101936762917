import { IS_LOGGED_IN_TO_APP_KEY } from '../../constants/Common';
import { store } from '../../store/store';
import { getAuthAccessToken } from './authTokenManager';
import { getTokenPayload, Role, TypeOfRole } from '../../utils/authApi/jwt';

export const getLoggedUserId = (): string => {
	return store.getState().me?.profile?.uid as string;
};

export const isUserLogged = (): boolean => {
	return !!getAuthAccessToken();
};
export const setIsLoggedFlagInCookies = () => {
	document.cookie = `${IS_LOGGED_IN_TO_APP_KEY}=${isUserLogged()};domain=.recast.tv`;
};

export const loggedOutUserActionWrapper = (loggedInUserAction: () => void, loggedOutUserAction: () => void) => {
	!isUserLogged() ? loggedOutUserAction() : loggedInUserAction();
};

export const getChannelsFromAuthToken = (): Role | undefined => {
	return getTokenPayload()?.roles?.find(role => role.name === TypeOfRole.ChannelManager);
};
