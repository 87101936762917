import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { refreshAndAddToken, getBaseApiUrl } from '../api/api';
import { User } from '../mediaApi';
import { getChannelsFromAuthToken } from '../../domain/auth/authManager';
import { getPromisesResults } from '../utils';
import { getNotDeletedChannels } from '../../domain/user/channelManager';

import type { Tariff } from '@content-technology-partners-ltd/shared-data-access';

const partnerClient: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

axiosRetry(partnerClient, { retries: 2, retryDelay: (retryCount: number) => retryCount * 500 });

const getPartnerApiUrl = (suffix: string): string => `${getBaseApiUrl()}/partner/${suffix}`;

const addTokenIfRequired = (config: InternalAxiosRequestConfig) => {
	return refreshAndAddToken(config);
};

partnerClient.interceptors.request.use(addTokenIfRequired, Promise.reject);

export const getLiveChannelDetails = async (channelId: string): Promise<User> => {
	const response = await partnerClient.get(getPartnerApiUrl(`channel/${channelId}`));
	return response.data.data;
};

export const getChannelDetails = async (channelId: string): Promise<User> => {
	const response = await partnerClient.get(`${getBaseApiUrl()}/api/publisher/channel/${channelId}`);
	return response.data.data;
};

export const getChannelPasses = async (channelUid: string): Promise<Tariff[]> => {
	const response = await partnerClient.get(`${getBaseApiUrl()}/api/publisher/channel/${channelUid}/tariffs`);
	const tariffs: Tariff[] = response.data.data.expanded ?? [];
	return tariffs.filter(tariff => tariff.type === 'CHANNEL_PASS');
};

export const getMyChannels = async () => {
	const channelManager = getChannelsFromAuthToken();
	if (!channelManager?.resources) return [];

	const channelPromises = channelManager.resources.map(channel =>
		getChannelDetails(channel.id).then(result => result)
	);

	const channels = getPromisesResults(await Promise.allSettled(channelPromises));
	return getNotDeletedChannels(channels);
};

export const getWidgetById = async (widgetId: string) => {
	const response = await partnerClient.get(`${getBaseApiUrl()}/api/public/widget/${widgetId}`);
	return response.data.data;
};

export const getAffiliatesByChannelId = async (channelId: string): Promise<{ affiliates: string[] }> => {
	const response = await partnerClient.get(`${getBaseApiUrl()}/api/public/channel/${channelId}/affiliates`);
	return response.data.data;
};
