import { WatchedVideo } from '../../../utils/mediaApi/video';
import { WATCHED_VIDEOS_KEY } from '../../../constants/Common';
import dayjs from 'dayjs';

export const getAlreadyWatchedVideos = (): WatchedVideo[] => {
	return JSON.parse(localStorage.getItem(WATCHED_VIDEOS_KEY) || '[]') as WatchedVideo[];
};

export const saveWatchedVideo = (videoUid: string) => {
	const watchedVideos = getAlreadyWatchedVideos();
	watchedVideos.push({ videoUid: videoUid, date: dayjs().format() });
	localStorage.setItem(WATCHED_VIDEOS_KEY, JSON.stringify(watchedVideos));
};
