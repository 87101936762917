import { ContentActions } from './actions';
import { VideoItem } from '../../utils/mediaApi/video';
import { User } from '../../utils/mediaApi';
import { ProgressStatus } from '../../constants/Common';
import type { ChannelListing, VideoListing } from '@content-technology-partners-ltd/shared-data-access';

export interface ContentState {
	playlist: {
		videos?: VideoListing[];
		channel?: ChannelListing;
	};
	dashboard: {
		discover: {
			publishers: {
				users: User[];
				status: ProgressStatus;
			};
		};
	};
	videosSearchResult: VideoItem[];
	usersSearchResult: User[];
}

const initialState: ContentState = {
	playlist: {
		videos: []
	},
	dashboard: {
		discover: {
			publishers: {
				users: [],
				status: ProgressStatus.INIT
			}
		}
	},
	videosSearchResult: [],
	usersSearchResult: []
};

export default function contentReducer(state = initialState, action: ContentActions): ContentState {
	switch (action.type) {
		case 'UPDATE_DASHBOARD_STATUS':
			return {
				...state,
				dashboard: {
					...state.dashboard,
					discover: {
						...state.dashboard.discover,
						publishers: {
							...state.dashboard.discover.publishers,
							status:
								action.payload.discoverPublishersStatus ?? state.dashboard.discover.publishers.status
						}
					}
				}
			};

		case 'GET_DISCOVER_PUBLISHERS':
			return {
				...state,
				dashboard: {
					...state.dashboard,
					discover: {
						...state.dashboard.discover,
						publishers: {
							users: action.payload,
							status: ProgressStatus.FINISH
						}
					}
				}
			};

		case 'SET_PLAYLIST_VIDEOS':
			return {
				...state,
				playlist: action.payload
			};

		case 'UPDATE_USER_SEARCH':
			return {
				...state,
				usersSearchResult: action.payload
			};

		case 'UPDATE_VIDEO_SEARCH':
			return {
				...state,
				videosSearchResult: action.payload
			};

		default:
			return state;
	}
}
