import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { jsonToFormData } from '../utils';
import { VideosInfo } from './user';
import { store } from '../../store/store';
import { UNRESTRICTED_REGION } from '../../constants/Common';
import axiosRetry from 'axios-retry';
import { isLoggedAsAdmin } from '../../domain/user/userManager';
import { Channel } from './me';
import { refreshAndAddToken, getBaseApiUrl } from '../api/api';

export interface MediaAPIList<T> {
	items: T[];
}

export interface MediaAPIResponse<T> {
	success: boolean;
	data: T;
}

export interface APIResponsePagination<T> {
	items: T[];
	_links: {
		last: object;
		next: object;
		self: object;
	};
	_meta: {
		totalCount: number;
		pageCount: number;
		currentPage: number;
		perPage: number;
	};
}

export interface AxiosRequestConfigProps extends AxiosRequestConfig {
	['Content-Type']?: string;
}

export type SectionType = 'CUSTOM' | 'LATEST' | 'LIVE_NOW' | 'SCHEDULED' | 'SHARED_BY' | 'CHANNEL_PASSES';

export interface Section {
	name: string;
	type: SectionType;
	featuredVideoUids: string[];
}

export interface User extends Channel {
	affiliates: string[];
	followers: number;
	following: number;
	isLivestreamer: boolean;
	isPublisher: boolean;
	isAgent: boolean;
	shareUrl: string;
	videosInfo: VideosInfo;
	['key']: any;

	/**
	 * fallback to fix issue with missing channel avatars on search results DEV-6430,
	 * we should be able to remove it once channel search will be moved out of media api
	 */
	profileImage?: string;

	sections: Section[];
	trailerVideoIds: string[];
}

export interface Country {
	name: string;
	code: string;
	enabled: number;
	validateByEmail: number;
}

const axiosConfig = {
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
} as AxiosRequestConfig;

const client: AxiosInstance = axios.create(axiosConfig);
const clientWithRetries: AxiosInstance = axios.create(axiosConfig);
axiosRetry(clientWithRetries, { retries: 3, retryDelay: (retryCount: number) => retryCount * 500 });

const getMediaApiUrl = (suffix: string): string => `${getBaseApiUrl()}/v2/${suffix}`;

export const cancelToken = axios.CancelToken;

const isAuthorizedUrl = (url: string | undefined): boolean => {
	if (!url) return false;

	const authorizedUrls = [
		'auth/login',
		'auth/logout',
		'auth/refresh-token',
		'user/register',
		'user/resetPassword',
		'user/new-password'
	];

	return !authorizedUrls.some(authorizedUrl => url.includes(authorizedUrl));
};

const addTokenIfRequired = (config: InternalAxiosRequestConfig) => {
	if (isAuthorizedUrl(config.url)) {
		return refreshAndAddToken(config, 'Authorization', 'Bearer ');
	}
	return config;
};

client.interceptors.request.use(addTokenIfRequired, Promise.reject);

export const mediaApiClientWithoutTokenRefresh: AxiosInstance = axios.create(axiosConfig);
mediaApiClientWithoutTokenRefresh.interceptors.request.use(refreshAndAddToken, Promise.reject);

export const GET = <U>(url: string, data?: Record<string, unknown>) => {
	type Response = AxiosResponse<MediaAPIResponse<U>>;
	return client.get<typeof data, Response>(getMediaApiUrl(url), data);
};

export const GETWithRetries = <U>(url: string, data?: Record<string, unknown>) => {
	type Response = AxiosResponse<MediaAPIResponse<U>>;
	return clientWithRetries.get<typeof data, Response>(getMediaApiUrl(url), data);
};

export const GETWithCurrentLocation = <U>(url: string) => {
	type Response = AxiosResponse<MediaAPIResponse<U>>;
	const userCurrentLocation = store.getState().me.currentLocation;
	const config = {
		headers: { 'Country-Override': isLoggedAsAdmin() ? UNRESTRICTED_REGION.code : userCurrentLocation }
	};
	return client.get<any, Response>(getMediaApiUrl(url), config);
};

export const POST = <U>(url: string, data?: Record<string, unknown> | FormData, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<MediaAPIResponse<U>>;
	return client.post<typeof data, Response>(getMediaApiUrl(url), data, config);
};

export const PUT = <U>(url: string, data?: Record<string, unknown>, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<MediaAPIResponse<U>>;
	return client.put<typeof data, Response>(getMediaApiUrl(url), data, config);
};

/** Post to an endpoint which expects form-encoded data rather than JSON */
export const formPOST = <U>(url: string, data = {}, config?: AxiosRequestConfig) => {
	const newConfig = {
		...(config || {}),
		headers: { ...(config?.headers || {}), 'Content-Type': 'multipart/form-data' }
	};
	return POST<U>(url, jsonToFormData(data), newConfig);
};

export const DELETE = <U>(url: string, data?: Record<string, unknown>) => {
	return client.delete<typeof data, AxiosResponse<MediaAPIResponse<U>>>(getMediaApiUrl(url), { data: data });
};
