import { PaymentActions, PaymentActionTypes } from './actions';
import { PaymentConfig } from '../../utils/paymentApi/payment';

export interface PaymentState {
	paymentConfig?: PaymentConfig;
}

const initialState: PaymentState = {};

export default function notificationReducer(state = initialState, action: PaymentActions): PaymentState {
	switch (action.type) {
		case PaymentActionTypes.GET_PAYMENT_CONFIG:
			return {
				...state,
				paymentConfig: action.payload
			};

		default:
			return state;
	}
}
