import { PallyconDrmStrategy } from './drms/PallyconDrmStrategy';
import { BuyDrmStrategy } from './drms/BuyDrmStrategy';
import videojs from 'video.js';
import { browserName } from 'react-device-detect';
import { captureException } from '@sentry/react';
import { getLoggedUserId } from '../auth/authManager';
import { DrmInfo } from '../../utils/mediaApi/video';

export interface VideoJsSourceConfig extends videojs.Tech.SourceObject {
	keySystems: any;
}

export enum DrmType {
	Widevine = 'Widevine',
	PlayReady = 'PlayReady',
	FairPlay = 'FairPlay'
}

export enum DrmProviderName {
	BuyDrm = 'buydrm',
	Pallycon = 'pallycon',
	None = ''
}

export interface DrmStrategy {
	getDrmConfig: (streamUrl: string, drmId: string, userUid: string) => Promise<VideoJsSourceConfig>;
}

const getDrmProvider = (drmProviderInfo?: DrmInfo): DrmStrategy | null => {
	switch (drmProviderInfo?.provider) {
		case DrmProviderName.BuyDrm:
			return new BuyDrmStrategy(drmProviderInfo);
		case DrmProviderName.Pallycon:
			return new PallyconDrmStrategy();
		default:
			return null;
	}
};

export const getPlayerConfig = (
	streamUrl: string,
	drmId: string,
	drmProviderInfo: DrmInfo
): Promise<VideoJsSourceConfig> => {
	const userUid = getLoggedUserId();
	const drmProvider = getDrmProvider(drmProviderInfo);

	if (drmProvider) {
		return drmProvider.getDrmConfig(streamUrl, drmId, userUid);
	}

	captureException('Drm not supported for browser: ' + browserName);
	return Promise.reject();
};
