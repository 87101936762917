export enum RoutePaths {
	Verification = '/verification',
	Register = '/register',
	Login = '/login',
	Home = '/home',
	HomeInvite = '/home/invite',
	Live = '/live',
	Root = '/',
	Landing = '/landing',
	NewPassword = '/new-password',
	ResetPassword = '/reset-password',
	EditPreferences = '/edit-preferences',
	EditCategories = '/edit-categories',
	SetPreferences = '/set-preferences',
	Follow = '/follow',
	WatchVideo = '/video',
	ConfirmPurchase = '/confirm-purchase',
	AddCreditCard = '/add-credit-card',
	User = '/user',
	Me = '/me',
	MeInfo = '/me-info',
	Notifications = '/notifications',
	NotificationsManage = '/manage-notifications',
	NotificationsUnsubscribe = '/unsubscribe-notifications',
	NotificationsSettings = '/notifications-settings',
	NotificationsInfo = '/notifications-info',
	Settings = '/settings',
	EditProfile = '/edit-profile',
	SocialDisconnect = '/edit-profile/social-disconnect',
	FAQs = '/faqs',
	Wallet = '/wallet',
	WalletInfo = '/wallet-info',
	PurchaseCasts = '/purchase-casts',
	Explore = '/explore',
	Channels = '/channels',
	Category = '/category',
	Categories = '/categories',
	Embedded = '/embedded',
	Account = '/account',
	AccountLogin = '/account/login',
	AccountTopUp = '/account/top-up',
	SocialSignUpCallback = '/social-signup-callback',
	AccountPurchase = '/account/purchase',
	NotFound = '/not-found',
	PopUp = '/pop-up'
}

export default RoutePaths;
