import {
	NATIVE_SOURCE_URL_PARAM_VALUE_ANDROID,
	IS_ANDROID_NATIVE_SESSION_KEY,
	NATIVE_REDIRECT_URL_SCHEME,
	ANDROID_NATIVE_INTERNAL_ROUTE,
	REDIRECTED_FROM_NATIVE_SOURCE_URL_PARAM_KEY,
	IS_REDIRECTED_FROM_ANDROID_NATIVE_SESSION_KEY,
	NATIVE_SOURCE_URL_PARAM_KEY
} from '../../constants/Common';
import { getUrlParam, getUrlQueryWithoutParam } from '../../utils/utils';
import { isIOS } from 'react-device-detect';
import { updateUrlWithoutRerender } from '../browser/browserManager';
import { History } from 'history';

export const isInStandaloneMode = (): boolean => {
	const windowsObject = window as any;
	return windowsObject.matchMedia('(display-mode: standalone)').matches || windowsObject.navigator.standalone;
};

export const isNotInstalledAsPwaOnIOs = (): boolean => {
	return isIOS && !isInStandaloneMode();
};

export const preventShowDefaultInstallPrompt = () => {
	window.addEventListener('beforeinstallprompt', e => {
		e.preventDefault();
	});
};

export const handleAndroidNativeSessionTokens = () => {
	if (getUrlParam(NATIVE_SOURCE_URL_PARAM_KEY) === NATIVE_SOURCE_URL_PARAM_VALUE_ANDROID) {
		sessionStorage.setItem(IS_ANDROID_NATIVE_SESSION_KEY, 'true');
	}
	if (getUrlParam(REDIRECTED_FROM_NATIVE_SOURCE_URL_PARAM_KEY) === NATIVE_SOURCE_URL_PARAM_VALUE_ANDROID) {
		sessionStorage.setItem(IS_REDIRECTED_FROM_ANDROID_NATIVE_SESSION_KEY, 'true');
	}
};

export const clearRedirectedFromNativeSource = (history: History) => {
	const query = getUrlQueryWithoutParam(REDIRECTED_FROM_NATIVE_SOURCE_URL_PARAM_KEY);
	const urlWithoutParam = `${history.location.pathname}${query ? `?${query}` : ''}`;
	updateUrlWithoutRerender(urlWithoutParam);
	sessionStorage.removeItem(IS_REDIRECTED_FROM_ANDROID_NATIVE_SESSION_KEY);
};

export const isAndroidNative = () => sessionStorage.getItem(IS_ANDROID_NATIVE_SESSION_KEY) === 'true';

export const isRedirectedFromAndroidNative = () =>
	sessionStorage.getItem(IS_REDIRECTED_FROM_ANDROID_NATIVE_SESSION_KEY) === 'true';

export const redirectAndroidNativeToBrowser = (route: string) => {
	const redirectUrl = `${NATIVE_REDIRECT_URL_SCHEME}${route}`;
	window.location.replace(redirectUrl);
};

export const getAndroidNativeHref = (query?: string): string => {
	const baseUrl = `${NATIVE_REDIRECT_URL_SCHEME}${ANDROID_NATIVE_INTERNAL_ROUTE}`;

	return query ? `${baseUrl}?${query}` : baseUrl;
};
