import type { MouseEvent, RefObject } from 'react';
import { captureException } from '@sentry/react';
import { isDesktop, isIOS, isMobileSafari, isSafari } from 'react-device-detect';
import { isInStandaloneMode } from '../app/pwaManager';
import {
	ORIENTATION_TYPE,
	SUPPORT_EMAIL,
	SMALL_SCREEN_WIDTH_PX,
	XXS_SCREEN_WIDTH_PX,
	LARGE_SCREEN_WIDTH_PX
} from '../../constants/Common';

export const clearHistoryStateWithoutRerender = () => {
	window.history.replaceState(null, '');
};

export const updateUrlWithoutRerender = (path: string) => {
	window.history.replaceState(null, '', path);
};

export const copyToClipboard = (value: string) => {
	const textField = document.createElement('textarea');
	textField.innerText = value;
	document.body.appendChild(textField);
	textField.select();
	document.execCommand('copy');
	textField.remove();
};

const nav: any = window.navigator;

export const browserHasNativeShare = !!nav.share;

export const nativeShareLink = (url: string, title = '', onFinally?: () => void) => {
	if (browserHasNativeShare) {
		nav.share({ title, url })
			.catch(captureException)
			.finally(() => onFinally?.());
	}
};

export const getBrowserInfo = () => {
	const appName = navigator.appName,
		ua = navigator.userAgent;
	let tem;
	let browser = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (browser && (tem = ua.match(/version\/([.\d]+)/i)) != null) browser[2] = tem[1];
	browser = browser ? [browser[1], browser[2]] : [appName, navigator.appVersion, '-?'];
	return browser;
};

export const forceReloadPage = () => {
	window.location.reload();
};

export const isLandscape = (angle: number) => {
	return Math.abs(angle) === 90;
};

export const getOrientationType = (event: any): ORIENTATION_TYPE => {
	let orientationType: ORIENTATION_TYPE;

	if (isIOS) {
		const angle = event?.target?.window?.orientation;
		orientationType = isLandscape(angle) ? ORIENTATION_TYPE.LANDSCAPE : ORIENTATION_TYPE.PORTRAIT;
	} else {
		orientationType = event?.target?.screen?.orientation.type;
	}

	if (orientationType.includes(ORIENTATION_TYPE.PORTRAIT)) {
		return ORIENTATION_TYPE.PORTRAIT;
	}
	return ORIENTATION_TYPE.LANDSCAPE;
};

export const reflowHtmlElement = (element: HTMLElement) => {
	element.offsetHeight;
	element.style.display = 'none';
	setTimeout(() => {
		element.style.display = 'flex';
	}, 0);
};

export const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
};

export const isOnXXSmallScreen = (): boolean => getWindowDimensions().width <= XXS_SCREEN_WIDTH_PX;

export const isOnSmallScreen = (): boolean => getWindowDimensions().width <= SMALL_SCREEN_WIDTH_PX;

export const isOnLargeScreen = (): boolean => getWindowDimensions().width <= LARGE_SCREEN_WIDTH_PX;

export const getPositionOfClickableElement = (e: MouseEvent<any>) => {
	return e.currentTarget.getBoundingClientRect();
};

export const getPositionOfElementRef = (ref: RefObject<HTMLElement>) => {
	return ref.current?.getBoundingClientRect();
};

export const isDesignForDesktop = (): boolean => {
	const DESKTOP_DESIGN_BREAKPOINT = 450;
	return isDesktop && getWindowDimensions().width > DESKTOP_DESIGN_BREAKPOINT;
};

export const sendEmail = (subject: string, body: string, receiver = SUPPORT_EMAIL) => {
	location.href = `mailto:${receiver}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};

export const isSupportedPictureInPicture = (): boolean => {
	return 'pictureInPictureEnabled' in document && !(isIOS && isInStandaloneMode());
};

export const isSafariBrowser = () => isSafari || isMobileSafari;

export const getCurrentOrientation = (): ORIENTATION_TYPE => {
	return window.innerWidth <= window.innerHeight ? ORIENTATION_TYPE.PORTRAIT : ORIENTATION_TYPE.LANDSCAPE;
};
