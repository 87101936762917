import { AppThunk } from '../../store/store';
import { PlayedVideo, Balance, Transaction, Account } from '../../utils/mediaApi/me';
import * as meApi from '../../utils/mediaApi/me';
import * as authApi from '../../utils/authApi/user';
import * as partnerApi from '../../utils/partnerApi/partner';
import { User } from '../../utils/mediaApi';
import { GetUserContactMethodsResponse } from '../../utils/authApi/user';
import { decodeJwtToken, TokenPayload } from '../../utils/authApi/jwt';
import { getAuthAccessToken } from '../../domain/auth/authTokenManager';
import { MarketingConsentType } from '../../constants/Common';
import { reduceNotificationPreferencesToType } from '../../domain/notifications/notificationsManager';
import { isChannelListing } from '../../domain/user/channelListingManager';
import { fetchUsersChannelPasses, isPurchase, isTariff } from '../../utils/publicApi/channelPasses';

import type { ChannelListing } from '@content-technology-partners-ltd/shared-data-access';

export type UserActionTypes =
	| 'ME_PROFILE'
	| 'ME_PROFILE_IMAGE'
	| 'FOLLOW_USER'
	| 'UNFOLLOW_USER'
	| 'ME_FOLLOWING'
	| 'GET_RECOMMENDED_USERS'
	| 'UPDATE_PROFILE'
	| 'SET_PLAYED_VIDEOS'
	| 'ADD_PLAYED_VIDEO'
	| 'GET_USER_TRANSACTIONS'
	| 'SET_USER_BALANCE'
	| 'GET_USER_ACCOUNT'
	| 'GET_USER_TARIFFS'
	| 'DELETE_USER'
	| 'SET_RECENTLY_PLAYED_VIDEOS'
	| 'SET_REPOSTED_VIDEOS'
	| 'SET_VIDEO_PURCHASE'
	| 'CLEAR_VIDEO_PURCHASE'
	| 'GET_CURRENT_LOCATION'
	| 'FOLLOW_UNFOLLOW_USERS'
	| 'GET_MY_CHANNELS'
	| 'GET_MY_CHANNEL_PASSES'
	| 'SET_MY_CHANNEL_PASSES'
	| 'GET_MARKETING_CONSENT'
	| 'REFRESH_TOKEN_PAYLOAD'
	| 'UPDATE_MARKETING_CONSENT'
	| 'SET_CURRENT_CHANNEL'
	| 'GET_CONTACT_METHODS';

export interface UserAction {
	type: UserActionTypes;
	payload: any;
}

export interface ProfileAction extends UserAction {
	type: 'ME_PROFILE';
	payload: meApi.MeAll;
}

export interface SetFollowAction extends UserAction {
	type: 'FOLLOW_USER' | 'UNFOLLOW_USER';
	payload: User;
}

export interface SetFollowUnfollowActions extends UserAction {
	type: 'FOLLOW_UNFOLLOW_USERS';
	payload: {
		follow: User[];
		unfollow: User[];
	};
}

export interface UpdateProfileAction extends UserAction {
	type: 'UPDATE_PROFILE';
	payload: User;
}

export interface SetPayedVideosAction extends UserAction {
	type: 'SET_PLAYED_VIDEOS';
	payload: PlayedVideo[];
}

export interface AddPlayedVideoAction extends UserAction {
	type: 'ADD_PLAYED_VIDEO';
	payload: string;
}

export interface SetRecentlyPlayedAdvertVideosAction extends UserAction {
	type: 'SET_RECENTLY_PLAYED_VIDEOS';
	payload: string[];
}

export interface GetUserTransactionsAction extends UserAction {
	type: 'GET_USER_TRANSACTIONS';
	payload: Transaction[];
}

export interface SetUserBalanceAction extends UserAction {
	type: 'SET_USER_BALANCE';
	payload: Balance;
}

export interface GetUserAccountAction extends UserAction {
	type: 'GET_USER_ACCOUNT';
	payload: Account;
}

export interface DeleteUserAction extends UserAction {
	type: 'DELETE_USER';
}

export interface SetRepostedVideos extends UserAction {
	type: 'SET_REPOSTED_VIDEOS';
	reposts: string[];
}

export interface SetVideoPurchaseAction extends UserAction {
	type: 'SET_VIDEO_PURCHASE';
	videoUid: string;
}

export interface ClearVideoPurchaseAction extends UserAction {
	type: 'CLEAR_VIDEO_PURCHASE';
}

export interface GetCurrentLocation extends UserAction {
	type: 'GET_CURRENT_LOCATION';
}

export interface GetContactMethods extends UserAction {
	type: 'GET_CONTACT_METHODS';
	payload: GetUserContactMethodsResponse;
}

export interface GetMarketingConsent extends UserAction {
	type: 'GET_MARKETING_CONSENT';
}

export interface UpdateMarketingConsent extends UserAction {
	type: 'UPDATE_MARKETING_CONSENT';
}

export interface GetMyChannelAction extends UserAction {
	type: 'GET_MY_CHANNELS';
	payload: User[];
}

export interface GetMyChannelPassesAction extends UserAction {
	type: 'GET_MY_CHANNEL_PASSES';
	payload: meApi.ProfileChannelPasses;
}

export interface SetMyChannelPassesAction extends UserAction {
	type: 'SET_MY_CHANNEL_PASSES';
	payload: meApi.ProfileChannelPasses;
}

export interface RefreshTokenPayload extends UserAction {
	type: 'REFRESH_TOKEN_PAYLOAD';
	payload: TokenPayload;
}

export type UserActions =
	| ProfileAction
	| SetFollowAction
	| UpdateProfileAction
	| SetPayedVideosAction
	| AddPlayedVideoAction
	| GetUserTransactionsAction
	| SetUserBalanceAction
	| GetUserAccountAction
	| DeleteUserAction
	| SetRecentlyPlayedAdvertVideosAction
	| SetRepostedVideos
	| SetVideoPurchaseAction
	| ClearVideoPurchaseAction
	| GetCurrentLocation
	| SetFollowUnfollowActions
	| GetMyChannelAction
	| GetMyChannelPassesAction
	| SetMyChannelPassesAction
	| GetContactMethods
	| GetMarketingConsent
	| UpdateMarketingConsent
	| RefreshTokenPayload;

export const getProfileAction = async () => {
	return {
		type: 'ME_PROFILE',
		payload: await meApi.getProfile()
	};
};

export const getProfile = (): AppThunk => {
	return async dispatch => dispatch(await getProfileAction());
};

export const follow = (user: User | ChannelListing): AppThunk => {
	return async dispatch => {
		await meApi.followUser(user);
		dispatch({
			type: 'FOLLOW_USER',
			payload: await partnerApi.getLiveChannelDetails(isChannelListing(user) ? user.id : user.uid)
		});
	};
};

export const unfollow = (user: User | ChannelListing): AppThunk => {
	return async dispatch => {
		await meApi.unfollowUser(user);
		dispatch({
			type: 'UNFOLLOW_USER',
			payload: await partnerApi.getLiveChannelDetails(isChannelListing(user) ? user.id : user.uid)
		});
	};
};

export const followUnfollow = (follow: User[], unfollow: User[]): AppThunk => {
	return async dispatch => {
		await Promise.all([
			...follow.map(user => meApi.followUser(user)),
			...unfollow.map(user => meApi.unfollowUser(user))
		]);
		dispatch({
			type: 'FOLLOW_UNFOLLOW_USERS',
			payload: {
				follow: follow,
				unfollow: unfollow
			}
		});
	};
};

export const updateProfile = (userObj: Partial<User>): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'UPDATE_PROFILE',
			payload: await meApi.updateProfile(userObj)
		});
	};
};

export const getUserAccount = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_USER_ACCOUNT',
			payload: await meApi.getAccount()
		});
	};
};

export const setUserBalance = (localBalance: Balance | null = null): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'SET_USER_BALANCE',
			payload: localBalance ? localBalance : (await meApi.getAccount()).balance
		});
	};
};

export const setPlayedVideos = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'SET_PLAYED_VIDEOS',
			payload: await meApi.getPlayedVideos()
		});
	};
};

export const addPlayedVideo = (uid: string): AddPlayedVideoAction => ({
	type: 'ADD_PLAYED_VIDEO',
	payload: uid
});

export const getUserTransactions = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_USER_TRANSACTIONS',
			payload: await meApi.getUserTransactions()
		});
	};
};

export const getUserContactMethods = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_CONTACT_METHODS',
			payload: await authApi.getUserContactMethods()
		});
	};
};

export const deleteUser = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'DELETE_USER',
			payload: await authApi.deleteUser()
		});
	};
};

export const setUserRepostedVideos = (reposts: string[]): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'SET_REPOSTED_VIDEOS',
			payload: reposts
		});
	};
};

export const setVideoPurchase = (videoUid: string): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'SET_VIDEO_PURCHASE',
			payload: videoUid
		});
	};
};

export const clearVideoPurchase = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'CLEAR_VIDEO_PURCHASE'
		});
	};
};

export const getCurrentLocation = async () => {
	return {
		type: 'GET_CURRENT_LOCATION',
		payload: await meApi.getCurrentLocation()
	};
};

export const getMyChannels = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_MY_CHANNELS',
			payload: await partnerApi.getMyChannels()
		});
	};
};

export const getMyChannelPasses = (): AppThunk => {
	return async dispatch => {
		const channelPasses = await fetchUsersChannelPasses();
		const channelPassPurchases = channelPasses.filter(isPurchase);
		const channelPassTariffs = channelPasses.filter(isTariff);
		dispatch({
			type: 'SET_MY_CHANNEL_PASSES',
			payload: {
				purchases: channelPassPurchases,
				tariffs: channelPassTariffs
			}
		});
	};
};

export const setMyChannelPasses = (payload: meApi.ProfileChannelPasses): AppThunk => {
	return dispatch => {
		dispatch({
			type: 'SET_MY_CHANNEL_PASSES',
			payload
		});
	};
};

export const getMarketingConsent = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_MARKETING_CONSENT',
			payload: await meApi.getMarketingConsent()
		});
	};
};

export const updateMarketingConsent = (marketingConsent: number | null): AppThunk => {
	return async dispatch => {
		if (marketingConsent === MarketingConsentType.optedIn) {
			await reduceNotificationPreferencesToType();
		}
		dispatch({
			type: 'UPDATE_MARKETING_CONSENT',
			payload: await meApi.updateMarketingConsent(marketingConsent)
		});
	};
};

export const refreshTokenPayload = (): AppThunk => dispatch => {
	const decodedToken = decodeJwtToken(getAuthAccessToken());
	decodedToken &&
		dispatch({
			type: 'REFRESH_TOKEN_PAYLOAD',
			payload: decodedToken
		});
};
