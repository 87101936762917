export const IGNORED_SENTRY_ERRORS: (string | RegExp)[] = [
	//general
	`ResizeObserver loop limit exceeded`,
	`Request timed out`,
	/Non-Error promise rejection captured.*/,

	//virtuoso
	`Zero-sized element, this should not happen`,

	//videoJs
	`Cannot read property nodeName of null`,
	`Cannot read property 'nodeName' of null`,
	`Cannot read property 'play' of null`,
	`Cannot set property 'muted' of null`,
	`Invalid target; must be a DOM node or evented object.`,
	`AbortError: The operation was aborted.`,
	`AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22`,
	`AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22`,
	// eslint-disable-next-line max-len
	`AbortError: The play() request was interrupted because the media was removed from the document. https://goo.gl/LdLk22`,

	//autoplay
	// eslint-disable-next-line max-len
	`NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.`
];

export const BLACKLISTED_SENTRY_URLS: RegExp[] = [/vendors~main.*/];
