import { UserActions } from './actions';
import { MeAll, MeProfile, transformPlayedVideosToMap } from '../../utils/mediaApi/me';
import { getDarkChannels, getLiveChannels, getNotLiveChannels } from '../../domain/user/channelManager';

export default function meReducer(state: MeAll = { watchVideoUid: '' } as MeAll, act: UserActions): MeAll {
	switch (act.type) {
		case 'ME_PROFILE':
			return {
				...state,
				...act.payload
			};

		case 'UPDATE_PROFILE':
			return {
				...state,
				profile: {
					...(state.profile as MeProfile),
					...act.payload,
					phone: (state.profile as MeProfile).phone,
					email: (state.profile as MeProfile).email
				}
			} as MeAll;

		case 'FOLLOW_USER': {
			if (state.profile && state.following) {
				const nowFollowing = [...state.following, act.payload];
				return {
					...state,
					profile: {
						...state.profile,
						following: nowFollowing.length
					},
					following: nowFollowing
				};
			}
			return state;
		}

		case 'UNFOLLOW_USER': {
			if (state.profile && state.following) {
				const nowFollowing = state.following.filter(item => item.uid !== act.payload.uid);
				return {
					...state,
					profile: {
						...state.profile,
						following: nowFollowing.length
					},
					following: nowFollowing
				};
			}
			return state;
		}

		case 'FOLLOW_UNFOLLOW_USERS':
			if (state.profile && state.following) {
				return {
					...state,
					profile: {
						...state.profile,
						following: state.profile.following + act.payload.follow.length - act.payload.unfollow.length
					},
					following: [
						...state.following.filter(
							user => !act.payload.unfollow.some(unfollow => unfollow.uid === user.uid)
						),
						...act.payload.follow
					]
				};
			}
			return state;

		case 'SET_PLAYED_VIDEOS':
			return {
				...state,
				playedVideos: act.payload,
				playedVideosMap: transformPlayedVideosToMap(act.payload)
			};

		case 'ADD_PLAYED_VIDEO':
			return {
				...state,
				playedVideosMap: { ...state.playedVideosMap, [act.payload]: true }
			};

		case 'SET_RECENTLY_PLAYED_VIDEOS':
			return {
				...state,
				recentlyPlayedVideos: act.payload
			};

		case 'GET_USER_ACCOUNT':
			return {
				...state,
				account: act.payload
			};

		case 'SET_USER_BALANCE':
			if (state.account) {
				return {
					...state,
					account: {
						...state.account,
						balance: act.payload
					}
				};
			}
			return state;

		case 'GET_USER_TRANSACTIONS':
			return {
				...state,
				transactions: act.payload
			};

		case 'DELETE_USER':
			return {
				account: undefined,
				following: [],
				playedVideos: [],
				playedVideosMap: {},
				profile: undefined,
				transactions: [],
				...state
			};

		case 'SET_REPOSTED_VIDEOS':
			if (state.profile) {
				return {
					...state,
					profile: {
						...state.profile,
						reposts: act.payload
					}
				};
			}
			return state;

		case 'SET_VIDEO_PURCHASE':
			return {
				...state,
				watchVideoUid: act.payload
			};

		case 'CLEAR_VIDEO_PURCHASE':
			return {
				...state,
				watchVideoUid: ''
			};

		case 'GET_CURRENT_LOCATION':
			return {
				...state,
				currentLocation: act.payload.location,
				user_token: act.payload.user_token
			};

		case 'GET_CONTACT_METHODS':
			if (state.profile) {
				return {
					...state,
					profile: {
						...state.profile,
						phone: act.payload.phone,
						email: act.payload.email
					}
				};
			}
			return state;

		case 'GET_MY_CHANNELS':
			return {
				...state,
				channels: getLiveChannels(act.payload),
				notLiveChannels: getNotLiveChannels(act.payload),
				darkChannels: getDarkChannels(act.payload)
			};

		case 'GET_MY_CHANNEL_PASSES':
			return {
				...state,
				channelPasses: act.payload
			};

		case 'SET_MY_CHANNEL_PASSES':
			return {
				...state,
				channelPasses: act.payload
			};

		case 'GET_MARKETING_CONSENT':
			return {
				...state,
				marketingPreference: act.payload.marketingConsent
			};

		case 'UPDATE_MARKETING_CONSENT':
			return {
				...state,
				marketingPreference: act.payload.marketingConsent
			};

		case 'REFRESH_TOKEN_PAYLOAD':
			return {
				...state,
				isPasswordSet: act.payload.isPasswordSet,
				socialConnections: act.payload.socialConnections
			};

		default:
			return state;
	}
}
