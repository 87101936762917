import dayjs from 'dayjs';
import {
	NotificationAttributes,
	NotificationsPreferences,
	NotificationStatus,
	NotificationType,
	SingleNotification,
	CommunicationChannel
} from '../../utils/notificationApi/types';
import { getVideosInfo, VideoItem, VideoStatus } from '../../utils/mediaApi/video';
import { groupBy } from 'lodash';
import { DateFormat, MarketingConsentType } from '../../constants/Common';
import { getIsBroadcast } from '../video/videoItemManager';
import { getLastReadNotificationDate, getReadNotificationsFromStorage } from './notificationsStorage';
import differenceBy from 'lodash/differenceBy';
import { User } from '../../utils/mediaApi';
import castCreditsIcon from '../../static/images/icons/cast_circle.svg';
import { store } from '../../store/store';
import { unsubscribeNewVideo, getNotificationSettings } from '../../utils/notificationApi/notifications';
import { saveNotificationsSettingsAction } from '../../store/notification/actions';
import { getChannelAvatar } from '../user/imagesManager';
import { replaceWithUnicode } from '../../utils/utils';
import { getChannelName } from '../user/channelManager';

const getNewDetailedVideos = async (notifications: SingleNotification[]) => {
	const newVideosIds = notifications
		.filter(
			notification =>
				notification.status === NotificationStatus.NOT_FETCHED &&
				(notification.type === NotificationType.NEW_VIDEO || notification.type === NotificationType.LIVE_EVENT)
		)
		.map(notification => notification.notificationId);

	return newVideosIds.length ? await getVideosInfo(newVideosIds) : [];
};

const getNotificationsWithDetailedVideos = async (notifications: SingleNotification[]): Promise<any> => {
	const detailedVideos = await getNewDetailedVideos(notifications);

	const newNotifications = notifications.map(notification => {
		const detailedVideo = detailedVideos.find(video => video.uid === notification.notificationId);

		if (detailedVideo) {
			const isPublished = detailedVideo.status === VideoStatus.PUBLISHED;
			if (!isPublished) return;

			return { ...notification, ...detailedVideo, status: NotificationStatus.NEW };
		}
		return notification;
	});

	return newNotifications.filter(Boolean);
};

export const getDetailedNotifications = async (notifications: SingleNotification[]): Promise<any> => {
	return getNotificationsWithDetailedVideos(notifications);
};

export const getNotificationsWithBasicAttributes = async (notifications: SingleNotification[]) => {
	return notifications.map((notification: SingleNotification): any => ({
		type: notification.type,
		notificationDate: notification.date,
		notificationId:
			notification.followerUid ?? notification.refereeUid ?? notification.videoId ?? notification.livestreamId,
		status:
			NotificationAttributes.NEW_REFERRAL in notification
				? NotificationStatus.NEW
				: NotificationStatus.NOT_FETCHED
	}));
};

export const getNotificationsWithUpdatedStatuses = (notifications: SingleNotification[]): SingleNotification[] => {
	const readNotifications = getReadNotificationsFromStorage();

	return notifications.map(notification => {
		if (readNotifications.includes(notification.notificationId)) {
			notification.status = NotificationStatus.READ;
		}
		return notification;
	});
};

export const getOnlyAllowedTypeOfNotifications = (notifications: SingleNotification[]): SingleNotification[] => {
	const DEPRECATED_TYPE_OF_NOTIFICATIONS = NotificationType.NEW_FOLLOWER;
	return notifications.filter(notification => notification.type !== DEPRECATED_TYPE_OF_NOTIFICATIONS);
};

const getUnreadVideoNotifications = (videos: SingleNotification[]) => {
	const lastReadNotificationDate = getLastReadNotificationDate();

	return videos.filter(video =>
		dayjs(video.notificationDate).isAfter(dayjs(lastReadNotificationDate, DateFormat.API))
	);
};

const getLatestNotificationDate = (videos: SingleNotification[]) => {
	const notificationDates = videos.map(video => dayjs(video.notificationDate));
	return dayjs.max(notificationDates)?.format(DateFormat.API);
};

const prepareNewVideosNotification = (videos: SingleNotification[], videosOwner?: User) => {
	const isManyVideos = videos.length > 1;
	const [firstVideo] = videos;

	return {
		type: isManyVideos ? NotificationType.NEW_VIDEOS : NotificationType.NEW_VIDEO,
		user: videosOwner,
		numberOfNewVideos: videos.length,
		notificationId: isManyVideos ? videosOwner?.uid : firstVideo.uid,
		notificationDate: getLatestNotificationDate(videos),
		...(!isManyVideos && firstVideo)
	};
};

const getGroupedReadVideosNotifications = (videos: SingleNotification[], videosOwner: User) => {
	const lastWeek = dayjs().add(-1, 'week');

	const notificationsFromLastWeek = videos.filter(video => dayjs(video.notificationDate).isAfter(lastWeek));
	const notificationsOlderThanWeek = differenceBy(videos, notificationsFromLastWeek, 'uid');

	const groupedVideosByDay = groupBy(notificationsFromLastWeek, video =>
		dayjs(video.notificationDate)
			.startOf('day')
			.format()
	);

	const groupedVideosByMonth = groupBy(notificationsOlderThanWeek, video =>
		dayjs(video.notificationDate)
			.startOf('month')
			.format()
	);

	const groupedReadVideosNotifications = [];

	for (const video in groupedVideosByDay) {
		groupedReadVideosNotifications.push(prepareNewVideosNotification(groupedVideosByDay[video], videosOwner));
	}

	for (const video in groupedVideosByMonth) {
		groupedReadVideosNotifications.push(prepareNewVideosNotification(groupedVideosByMonth[video], videosOwner));
	}

	return groupedReadVideosNotifications;
};

const getNewVideosNotification = (videos: SingleNotification[]): SingleNotification[] => {
	//get user object from first video
	const videosOwner = videos[0].user;

	const unreadVideoNotifications = getUnreadVideoNotifications(videos);
	const readVideoNotifications = differenceBy(videos, unreadVideoNotifications, 'uid');

	const newVideoNotifications = [];

	if (unreadVideoNotifications.length) {
		newVideoNotifications.push(prepareNewVideosNotification(unreadVideoNotifications, videosOwner));
	}

	if (readVideoNotifications.length) {
		newVideoNotifications.push(...getGroupedReadVideosNotifications(readVideoNotifications, videosOwner));
	}

	return (newVideoNotifications as unknown) as SingleNotification[];
};

export const getGroupedNotifications = (notifications: SingleNotification[]): SingleNotification[] => {
	const newVideosNotifications = notifications.filter(
		notification => notification.type === NotificationType.NEW_VIDEO
	);

	const notificationsWithoutNewVideo = notifications.filter(
		notification => notification.type !== NotificationType.NEW_VIDEO
	);

	const groupedNotifications = Object.entries(groupBy(newVideosNotifications, video => video?.user?.uid));

	const groupedNotificationsByUserVideos = groupedNotifications.map(
		(groupedUserItem: [string, SingleNotification[]]) => {
			const userVideos = groupedUserItem[1];
			const isUserHasManyNewVideos = userVideos.length > 1;
			return isUserHasManyNewVideos ? getNewVideosNotification(userVideos) : userVideos[0];
		}
	);

	return [...notificationsWithoutNewVideo, ...groupedNotificationsByUserVideos.flat()] as SingleNotification[];
};

export const getNotificationParam = (
	notification: SingleNotification | undefined,
	translate: (translationId: string, defaultValue: string, options?: any) => string
) => {
	let title = '';
	let iconUrl = getChannelAvatar(notification?.user);
	const date = dayjs.utc(notification?.notificationDate).fromNow();
	const isVerified = notification?.user?.isPublisher;
	const userFullName = replaceWithUnicode(getChannelName(notification?.user));

	switch (notification?.type) {
		case NotificationType.NEW_VIDEO:
			title = getIsBroadcast((notification as unknown) as VideoItem)
				? translate(
						'components.notifications.entry.scheduledNewLive',
						'**{{fullName}}** has scheduled a new live event',
						{
							fullName: userFullName
						}
				  )
				: translate('components.notifications.entry.postedNewVideo', '**{{fullName}}** posted a new video', {
						fullName: userFullName
				  });
			break;
		case NotificationType.NEW_VIDEOS:
			title =
				notification.numberOfNewVideos > 1
					? translate(
							'components.notifications.entry.postedNewVideos',
							'**{{fullName}}** posted {{videosCount}} new videos',
							{
								fullName: userFullName,
								videosCount: notification.numberOfNewVideos
							}
					  )
					: translate(
							'components.notifications.entry.postedNewVideo',
							'**{{fullName}}** posted a new video',
							{
								fullName: userFullName
							}
					  );
			break;
		case NotificationType.NEW_REFERRAL:
			iconUrl = castCreditsIcon;
			title = translate(
				'components.notifications.entry.referralLinkUsed',
				'Your referral link has been used! You’ve earned!'
			);
			break;
		case NotificationType.LIVE_EVENT:
			title = translate('components.notifications.entry.aboutToGoLive', '**{{name}}** is about to go live ⏳', {
				name: notification.name
			});
			break;
	}

	return { iconUrl, title, isVerified, date };
};

export const getIsAnyNotificationUnread = (notifications: SingleNotification[]): boolean => {
	return notifications.some(notification => notification.status === NotificationStatus.NEW);
};

export const isEnabledNotificationsForLiveVideos = (notificationSettings: NotificationsPreferences): boolean => {
	const isEnabledAtLeastOneChannel = !!notificationSettings?.enabledChannels?.filter(Boolean).length;

	const isEnabledNotificationLiveType = !!notificationSettings?.communicationPreferences
		?.find(preference => preference.type === NotificationType.LIVE_EVENT)
		?.enabledChannels.filter(Boolean).length;

	return isEnabledAtLeastOneChannel && isEnabledNotificationLiveType;
};

export const getWebSocketUrl = (): string => {
	const baseURL = store.getState().endpoints.API_BASE.replace('https', 'wss');
	return `${baseURL}/notifications/ws`;
};

export const getIsAllCommunicationDisabled = (channelStates: CommunicationChannel[] | undefined) => {
	const marketingPreference = store.getState().me.marketingPreference;
	const isEmailMarketingDisabled = marketingPreference === MarketingConsentType.optedOut;

	return isEmailMarketingDisabled && !channelStates?.length;
};

const disableNotificationsForAllFollowedChannels = () => {
	const following = store.getState().me.following;
	if (!following?.length) return;

	const promiseArray = following.map((user: User) => unsubscribeNewVideo(user.uid));
	return Promise.all(promiseArray);
};

export const saveReducedNotificationPreferences = async (allowedType?: NotificationType) => {
	const channels = [CommunicationChannel.EMAIL, CommunicationChannel.WEBSOCKET];

	const notificationTypes = [NotificationType.LIVE_EVENT, NotificationType.NEW_REFERRAL, NotificationType.NEW_VIDEO];
	const preferences = {
		enabledChannels: channels,
		communicationPreferences: notificationTypes.map(type => {
			const isAllowedType = type === allowedType;
			return {
				type: type,
				enabledChannels: isAllowedType ? channels : []
			};
		})
	};
	store.dispatch(saveNotificationsSettingsAction(preferences));
};

export const reduceNotificationPreferencesToType = async (allowedType?: NotificationType) => {
	const notificationSettings = await getNotificationSettings();
	const isAllChannelsDisabled = getIsAllCommunicationDisabled(notificationSettings?.enabledChannels);
	if (isAllChannelsDisabled) {
		await disableNotificationsForAllFollowedChannels();
		await saveReducedNotificationPreferences(allowedType);
	}
};

//because api returns [""] and doesn't accept this format
export const getSettingsWithoutEmptyString = (preferences: NotificationsPreferences): NotificationsPreferences => {
	preferences.enabledChannels = preferences.enabledChannels?.filter(Boolean);
	preferences.communicationPreferences = preferences.communicationPreferences?.map(communicationPreference => {
		communicationPreference.enabledChannels = communicationPreference.enabledChannels.filter(Boolean);
		return communicationPreference;
	});
	return preferences;
};
