import { TokenPayload } from '../authApi/jwt';
import { addAuthStateListener } from '@content-technology-partners-ltd/shared-data-access';
import { identifyUser, identifyUserStop } from '@content-technology-partners-ltd/shared-flags-react';

export const FEATURE_FLAGS = {
	REMOVE_VIDEO_CARD_WATCH_BUTTON: 'remove_video_card_watch_button',
	LIVE_CHAT_AUTOLOAD: 'live_chat_autoload',
	MY_DATA_WALLET_2: 'my_data_wallet_2',
	ENABLE_COMMENTS: 'enable_comments',
	SIGNUP_PROVIDERS: 'signup_providers',
	ENABLE_WARN_ABOUT_GEOBLOCK: 'enable_warn_about_geoblock',
	ENABLE_BUSINESS_APP_BASE: 'enable_business_app_base',
	ENABLE_ACCOUNT_WALLET_LOZENGE: 'enable_account_wallet_lozenge',
	ACCOUNT_POPUP_ENABLED: 'account_popup_enabled'
} as const;

export interface DataSharePublisher {
	uid: string;
	link: string;
}

export const setupFeatureFlagsListeners = () => {
	let previousPayload: TokenPayload | null = null;

	return addAuthStateListener(async state => {
		const payload = state.tokenPayload;

		if (previousPayload === payload) {
			return;
		}
		previousPayload = payload;

		if (payload) {
			await identifyUser(payload.uid, {
				isAdmin: !!payload.isAdmin,
				isContributor: !!payload.isContributor,
				isPartnerStaff: !!payload.isPartnerStaff,
				isPublisher: !!payload.isPublisher
			});
		} else {
			await identifyUserStop();
		}
	});
};
