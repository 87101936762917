import axiosRetry from 'axios-retry';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { MediaAPIList, MediaAPIResponse } from '../mediaApi';
import { VideoItem } from '../mediaApi/video';
import { refreshAndAddToken, getBaseApiUrl } from '../api/api';

import type { VideoListing } from '@content-technology-partners-ltd/shared-data-access';

export interface ImageItem {
	id: number;
	ownerId: string;
	resourceId: string; // in this case this will be video ID
	description: string;
	imageType: string;
	imageUrl: string;
}

type Response<U> = AxiosResponse<MediaAPIResponse<U>>;

export const enum ThumbnailSizes {
	xlarge = 'xlarge',
	large = 'large',
	medium = 'medium',
	small = 'small'
}

export const imageThumbnailResolution = {
	[ThumbnailSizes.xlarge]: '1920x1080',
	[ThumbnailSizes.large]: '1440x1080',
	[ThumbnailSizes.medium]: '1138x640',
	[ThumbnailSizes.small]: '640x480'
};

export const enum ImageResourceType {
	video = 'video',
	channel = 'channel',
	partner = 'partner'
}

const axiosConfig = {
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
} satisfies AxiosRequestConfig;

const imageClient: AxiosInstance = Axios.create(axiosConfig);

const getImageApiUrl = (suffix: string): string => `${getBaseApiUrl()}/image/${suffix}`;

axiosRetry(imageClient, { retries: 2, retryDelay: (retryCount: number) => retryCount * 500 });

const addTokenIfRequired = (config: InternalAxiosRequestConfig) => {
	return refreshAndAddToken(config);
};

imageClient.interceptors.request.use(addTokenIfRequired, Promise.reject);

const getResourceImages = async (ids: string[], resourceType: ImageResourceType): Promise<ImageItem[]> => {
	if (!ids.length) {
		return [];
	}

	const imageResponse = await imageClient.get(getImageApiUrl(`${resourceType}/image?resourceIds=${ids.join(',')}`));
	return imageResponse.data.data;
};

const enrichVideosWithThumbnails = <U extends VideoItem>(videos: U[], imageItems?: ImageItem[]): U[] => {
	const thumbnailById: { [videoId: string]: ImageItem } = imageItems
		? imageItems.reduce((accumulator, target) => ({ ...accumulator, [target.resourceId]: target }), {})
		: {};

	return videos.map(videoItem => ({
		...videoItem,
		thumbnailImage: thumbnailById[videoItem.uid]
	}));
};

export const GETVideoAndThumbnails = async <U extends VideoItem>(
	videoPromise: Promise<Response<U>>,
	videoId: string
): Promise<VideoItem> => {
	const imagePromise = getResourceImages([videoId], ImageResourceType.video);
	const [videoResponse, imageItems] = await Promise.all([videoPromise, imagePromise]);
	return enrichVideosWithThumbnails([videoResponse.data.data], imageItems)[0];
};

export const GETVideoListAndThumbnails = async <U extends VideoItem>(
	videoListPromise: Promise<Response<MediaAPIList<U>>>,
	videoIds: string[]
) => {
	const imagePromise = getResourceImages(videoIds, ImageResourceType.video);
	const [videoResponse, imageItems] = await Promise.all([videoListPromise, imagePromise]);
	return enrichVideosWithThumbnails(videoResponse.data.data.items, imageItems);
};

export const getVideoListingThumbnailUrl = (video: VideoListing, size: ThumbnailSizes) => {
	return video.listingDisplay.thumbnail.image.uri + '?d=' + imageThumbnailResolution[size];
};
