import { User } from '../../utils/mediaApi';
import { isMobile } from 'react-device-detect';
import ProfileIcon from '../../static/images/avatar-default.png';
import ChannelBackground from '../../static/images/placeholders/recastBackground.jpg';
import type { ChannelListing } from '@content-technology-partners-ltd/shared-data-access';

export enum ImageDimension {
	small = '640x480',
	medium = '1440x1080',
	large = '1920x1080'
}

export const getChannelAvatar = (channel?: User): string => {
	const imageUrl = channel?.avatarImageDetails?.imageUrl ?? channel?.profileImage;

	if (imageUrl) {
		return `${imageUrl}?d=${ImageDimension.small}`;
	}

	return ProfileIcon;
};

// todo: remove once api doesn't return urls to old bucket (or it gets resolved other way)
const OLD_S3_BUCKET = 'uk.co.vicast.recastmedia.public';
const ignoreOldBucket = (uri: string | undefined): string | undefined => {
	return uri?.includes(OLD_S3_BUCKET) ? undefined : uri;
};

export const getChannelListingAvatar = (channel: ChannelListing): string => {
	const imageUrl = ignoreOldBucket(channel.display.avatar?.uri);
	return imageUrl ? `${imageUrl}?d=${ImageDimension.small}` : ProfileIcon;
};

export const getChannelBanner = (channel?: User, size?: ImageDimension): string => {
	const imageUrl = ignoreOldBucket(channel?.bannerImage);

	if (imageUrl) {
		const imageSize = size ?? isMobile ? ImageDimension.medium : ImageDimension.large;
		return `${imageUrl}?d=${imageSize}`;
	}

	return ChannelBackground;
};
