import { User } from '../../utils/mediaApi';
import { FIRST_TIME_LOGIN_DATE_KEY } from '../../constants/Common';
import { uniqBy } from 'lodash';
import { isInPastDays } from '../../utils/dateUtils';
import { PlayedVideo } from '../../utils/mediaApi/me';
import { store } from '../../store/store';
import dayjs from 'dayjs';
import { getTokenPayload } from '../../utils/authApi/jwt';
import { getIsLiveChannel } from './channelManager';
import { Category } from '../../utils/publicApi/categories';
import { deduplicateArray } from '../../utils/utils';

export const setFirstTimeLoginDate = () => {
	const currentDateString = dayjs().toString();
	localStorage.setItem(FIRST_TIME_LOGIN_DATE_KEY, currentDateString);
};

export const getFirstTimeLoginDate = () => {
	const firstTimeLoginDateString = localStorage.getItem(FIRST_TIME_LOGIN_DATE_KEY);
	if (firstTimeLoginDateString) {
		return dayjs(firstTimeLoginDateString);
	}
	return null;
};

export const clearFirstTimeLoginDate = () => {
	localStorage.removeItem(FIRST_TIME_LOGIN_DATE_KEY);
};

export const deduplicateUsers = <T>(users?: T[]): T[] => {
	return uniqBy(users, 'uid');
};

export const filterMeUser = (users: User[], meUid: string): User[] => {
	return users.filter(user => user.uid !== meUid);
};

export const sortChannelsByFollowersCount = (users: User[]): User[] => {
	return [...users].sort((user1: User, user2: User) => {
		return user2.followers - user1.followers;
	});
};

export const getChannelLastVideoCategoriesIds = (channel: User): number[] | null => {
	if ('recentVideoCategories' in channel.videosInfo) {
		const channelVideoCategories = channel.videosInfo.recentVideoCategories;
		return channelVideoCategories.length ? channelVideoCategories[0] : null;
	}
	return null;
};

export const isChannelLastVideoCategoryId = (channel: User, categoryId: number) => {
	const channelVideoCategories = getChannelLastVideoCategoriesIds(channel);
	return channelVideoCategories?.length ? channelVideoCategories.includes(categoryId) : false;
};

export const getChannelRecentVideosCategoriesNames = (channel: User, categories: Category[]): string[] => {
	if ('recentVideoCategories' in channel.videosInfo) {
		const channelUniqueCategoriesIds = deduplicateArray(channel.videosInfo.recentVideoCategories.flat());
		return channelUniqueCategoriesIds.map(categoryId => categories.find(i => i.id === categoryId)?.name ?? '');
	}
	return [];
};

export const filterChannelsWhoPublishedInPastDays = (users: User[], days: number): User[] => {
	return users.filter(user => user.videosInfo.latestVideoDate && isInPastDays(user.videosInfo.latestVideoDate, days));
};

export const filterChannelsWithNoPublishInPastDays = (users: User[], days: number): User[] => {
	return users.filter(
		user => !user.videosInfo.latestVideoDate || !isInPastDays(user.videosInfo.latestVideoDate, days)
	);
};

export const filterChannelsWithRecentVideoCategoriesIds = (channels: User[], categoriesIds: number[]): User[] => {
	return channels.filter(
		channel =>
			'recentVideoCategories' in channel.videosInfo &&
			channel.videosInfo.recentVideoCategories
				.flat()
				.some(recentVideoCategoryId => categoriesIds.includes(recentVideoCategoryId))
	);
};

export const filterChannelsWithoutRecentVideoCategoriesIds = (channels: User[], categoriesIds: number[]): User[] => {
	return channels.filter(
		channel =>
			'recentVideoCategories' in channel.videosInfo &&
			(!channel.videosInfo.recentVideoCategories.length ||
				channel.videosInfo.recentVideoCategories
					.flat()
					.some(recentVideoCategoryId => !categoriesIds.includes(recentVideoCategoryId)))
	);
};

export const filterChannelsByRecentVideoCategories = (users: User[], categoryId: number): User[] => {
	return users.filter(
		user =>
			'recentVideoCategories' in user.videosInfo &&
			user.videosInfo.recentVideoCategories.some(videoCategoriesIds => videoCategoriesIds.includes(categoryId))
	);
};

export const getFullSearchUserData = (searchUsers: User[], usersWithFullData: User[]): User[] => {
	return usersWithFullData.filter(user => searchUsers.some(searchUser => searchUser.uid === user.uid));
};

export const getPublishersAndNotFollowingUsers = (users: User[]): User[] => {
	const following: User[] | undefined = store.getState().me.following;
	return users.filter(user => {
		const isFollowing = !!following && following.some(followUser => user.uid === followUser.uid);
		if (!user.isPublisher || user.isAgent || isFollowing || !getIsLiveChannel(user)) {
			return false;
		}
		return true;
	});
};

export const isUserWatchedAnyVideo = (playedVideos?: PlayedVideo[]): boolean => {
	return !!playedVideos?.length;
};

export const isLoggedAsPublisher = (): boolean => {
	return !!getTokenPayload()?.isPartnerStaff;
};

export const isLoggedAsAdmin = (): boolean => {
	return !!getTokenPayload()?.isAdmin;
};

export const isUserDeleted = (user: User): boolean => {
	return user.username === 'deleted';
};

export const isAdminOfChannel = (channelUid: string): boolean => {
	return !!getTokenPayload()
		?.roles.filter(role => role.resources)
		.find(({ resources }) => resources.find(({ id }) => id === channelUid));
};
