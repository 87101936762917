import { NotificationActionTypes, NotificationsActions } from './actions';
import { NotificationsPreferences, SingleNotification } from '../../utils/notificationApi/types';

export interface NotificationState {
	notifications: SingleNotification[] | null;
	settings: NotificationsPreferences;
	isNewNotifications: boolean;
	isInitialized: boolean;
}

const initialState: NotificationState = {
	notifications: null,
	settings: {},
	isNewNotifications: false,
	isInitialized: false
};

export default function notificationReducer(state = initialState, action: NotificationsActions): NotificationState {
	switch (action.type) {
		case NotificationActionTypes.SET_IS_INITIALIZED:
			return {
				...state,
				isInitialized: action.payload
			};

		case NotificationActionTypes.SET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload
			};

		case NotificationActionTypes.SET_SINGLE_NOTIFICATION:
			return {
				...state,
				notifications: [action.payload, ...(state.notifications as SingleNotification[])]
			};

		case NotificationActionTypes.GET_NOTIFICATIONS_SETTINGS:
			return {
				...state,
				settings: {
					...state.settings,
					...action.payload
				}
			};

		case NotificationActionTypes.MARK_NOTIFICATIONS_AS_READ:
			return {
				...state,
				notifications: action.payload
			};

		case NotificationActionTypes.SET_IS_NEW_NOTIFICATIONS:
			return {
				...state,
				isNewNotifications: action.payload
			};

		case NotificationActionTypes.CLEAR_NOTIFICATIONS:
			return {
				...state,
				notifications: []
			};

		default:
			return state;
	}
}
