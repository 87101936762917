import { PlayerActions, PlayerActionTypes } from './actions';

export interface PlayerState {
	forcedUiVideoIdList: string[];
	httpErrorUrl: string | undefined;
}

const initialState: PlayerState = {
	forcedUiVideoIdList: [],
	httpErrorUrl: undefined
};

export default function playerReducer(state = initialState, action: PlayerActions): PlayerState {
	switch (action.type) {
		case PlayerActionTypes.ADD_FORCED_PLAYER_UI_VIDEO_ID_LIST:
			if (!action.payload) return state;
			return {
				...state,
				forcedUiVideoIdList: Array.from(new Set([...state.forcedUiVideoIdList, action.payload]))
			};

		case PlayerActionTypes.REMOVE_FORCED_PLAYER_UI_VIDEO_ID_LIST:
			if (!action.payload) return state;
			return {
				...state,
				forcedUiVideoIdList: state.forcedUiVideoIdList.filter(id => id !== action.payload)
			};

		case PlayerActionTypes.SET_PLAYER_HTTP_ERROR_URL:
			return {
				...state,
				httpErrorUrl: action.payload
			};

		default:
			return state;
	}
}
