import { store } from '../store';
import { AppThunk } from '../../store/store';
import { getNotificationSettings, saveNotificationSettings } from '../../utils/notificationApi/notifications';
import { NotificationsPreferences, NotificationStatus, SingleNotification } from '../../utils/notificationApi/types';
import {
	saveLastReadNotificationDate,
	saveReadNotificationsInStorage
} from '../../domain/notifications/notificationsStorage';

export enum NotificationActionTypes {
	SET_IS_INITIALIZED = 'SET_IS_INITIALIZED',
	SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
	SET_SINGLE_NOTIFICATION = 'SET_SINGLE_NOTIFICATION',
	GET_NOTIFICATIONS_SETTINGS = 'GET_NOTIFICATIONS_SETTINGS',
	SAVE_NOTIFICATIONS_SETTINGS = 'SAVE_NOTIFICATIONS_SETTINGS',
	CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
	MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ',
	SET_IS_NEW_NOTIFICATIONS = 'SET_IS_NEW_NOTIFICATIONS'
}

interface ContentAction {
	type: NotificationActionTypes;
}

export interface GetNotificationsSettingsAction extends ContentAction {
	type: NotificationActionTypes.GET_NOTIFICATIONS_SETTINGS;
	payload: NotificationsPreferences;
}

export interface SetNotificationsAction extends ContentAction {
	type: NotificationActionTypes.SET_NOTIFICATIONS;
	payload: SingleNotification[];
}

export interface SetSingleNotificationAction extends ContentAction {
	type: NotificationActionTypes.SET_SINGLE_NOTIFICATION;
	payload: SingleNotification;
}

export interface SaveNotificationsSettingsAction extends ContentAction {
	type: NotificationActionTypes.SAVE_NOTIFICATIONS_SETTINGS;
	payload: NotificationsPreferences;
}

export interface MarkNotificationsAsReadAction extends ContentAction {
	type: NotificationActionTypes.MARK_NOTIFICATIONS_AS_READ;
	payload: SingleNotification[];
}

export interface ClearNotificationAction extends ContentAction {
	type: NotificationActionTypes.CLEAR_NOTIFICATIONS;
}

export interface SetIsNewNotifications extends ContentAction {
	type: NotificationActionTypes.SET_IS_NEW_NOTIFICATIONS;
	payload: boolean;
}

export interface SetIsInitializedAction extends ContentAction {
	type: NotificationActionTypes.SET_IS_INITIALIZED;
	payload: boolean;
}

export type NotificationsActions =
	| SetNotificationsAction
	| SetSingleNotificationAction
	| GetNotificationsSettingsAction
	| SaveNotificationsSettingsAction
	| MarkNotificationsAsReadAction
	| ClearNotificationAction
	| SetIsInitializedAction
	| SetIsNewNotifications;

export const setIsInitializedNotifications = (isInitialized: boolean): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.SET_IS_INITIALIZED,
			payload: isInitialized
		});
	};
};

export const setNotifications = (notifications?: SingleNotification[]): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.SET_NOTIFICATIONS,
			payload: notifications
		});
	};
};

export const setSingleNotification = (notification: SingleNotification): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.SET_SINGLE_NOTIFICATION,
			payload: notification
		});
	};
};

export const getNotificationsSettings = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.GET_NOTIFICATIONS_SETTINGS,
			payload: await getNotificationSettings()
		});
	};
};

export const saveNotificationsSettingsAction = (settings: NotificationsPreferences): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.SAVE_NOTIFICATIONS_SETTINGS,
			payload: await saveNotificationSettings(settings)
		});
	};
};

export const markNotificationsAsRead = (): AppThunk => {
	const notifications = store.getState().notification.notifications;

	const newNotifications = notifications?.map((notification: SingleNotification) => {
		notification.status = NotificationStatus.READ;
		return notification;
	});

	const newNotificationsId = newNotifications?.map((notification: SingleNotification) => notification.notificationId);
	if (newNotificationsId) {
		saveReadNotificationsInStorage(newNotificationsId);
		saveLastReadNotificationDate();
	}

	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.MARK_NOTIFICATIONS_AS_READ,
			payload: newNotifications
		});
	};
};

export const setIsNewNotifications = (isNewNotifications: boolean): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.SET_IS_NEW_NOTIFICATIONS,
			payload: isNewNotifications
		});
	};
};

export const clearNotifications = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: NotificationActionTypes.CLEAR_NOTIFICATIONS
		});
	};
};
