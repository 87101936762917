import type { ReactElement, ReactFragment } from 'react';
import { ApiMessage, SERVER_GENERAL_ERROR } from '../constants/Common';
import { User } from './mediaApi';
import dayjs from 'dayjs';
import { getChannelName } from '../domain/user/channelManager';

export const jsonToFormData = (jsonData: FormDataObject): FormData => {
	const formData = new FormData();
	for (const prop in jsonData) {
		formData.append(prop, jsonData[prop]);
	}
	return formData;
};

export const parseStrings = (data: object | string[] | string): string => {
	return typeof data === 'string' ? data : data instanceof Array ? Array.toString() : Object.values(data).toString();
};

export const getExtension = (value: string): string | undefined => {
	return value.split('.').pop();
};

export const evaluateValue = (value: string | ReactFragment | (() => ReactElement)) => {
	return typeof value === 'function' ? value() : value;
};

export const isInvalidCredentialsError = (message: ApiMessage) => {
	return message.type === 'InvalidCredentialsError' && message.severity === 'ERROR';
};

export const isServerGeneralError = (message: string) => {
	return message === SERVER_GENERAL_ERROR;
};

export const getApiError = (errorMessage: string): string => {
	return !errorMessage ? SERVER_GENERAL_ERROR : parseStrings(errorMessage);
};

export const getUrlParam = (name: string): string | null => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
};

export const getUrlQueryWithoutParam = (name: string): string => {
	const urlParams = new URLSearchParams(window.location.search);

	if (urlParams.has(name)) {
		urlParams.delete(name);
	}

	return urlParams.toString();
};

export const getUrlPathLastItem = (url: string): string => {
	return url.substring(url.lastIndexOf('/') + 1);
};

export const toTitleCase = (value: string) => {
	return value.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const getCollection = <T>(array?: T[]): T[] => {
	return array || [];
};

export const isEmptyObject = (object: any): boolean => {
	return Object.keys(object).length === 0 && object.constructor === Object;
};

export const getRetryPolicy = (): any => {
	const RETRY_DELAY_MS = 1000;
	return {
		retryDelay: (retryAttempt: number) =>
			Math.min(retryAttempt > 1 ? 2 ** retryAttempt * RETRY_DELAY_MS : RETRY_DELAY_MS, 30 * RETRY_DELAY_MS)
	};
};

export const joinMessagesAndTrim = (messages: string[]) => {
	return messages.join(' ').trim();
};

export const removeSpacesFromString = (value: string) => {
	return value.split(' ').join('');
};

export const getOnlyNumbersFromPhone = (phoneNumber: string) => {
	return phoneNumber.replace('+', '');
};

export const getRandomBoolean = (): boolean => {
	return !!Math.floor(Math.random() * 2);
};

export const getRandomNumber = (max: number): number => {
	return Math.floor(Math.random() * max);
};

export const formatNumericStringWithCommas = (value: number, fractionalDigits = 2): string => {
	return value.toLocaleString(undefined, {
		minimumFractionDigits: fractionalDigits,
		maximumFractionDigits: fractionalDigits
	});
};

export const convertHeicFileToJpeg = async (file: File | any, actionOnEnd: (file: string) => void) => {
	const { default: heic2any } = await import('heic2any');

	file.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
		const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

		heic2any({
			blob,
			toType: 'image/jpeg'
		})
			// TODO (mk): check `conversionResult` type
			.then(conversionResult => actionOnEnd(URL.createObjectURL(conversionResult as Blob | MediaSource)))
			.catch(e => console.log(e));
	});
};

export const isDefaultAvatarUrl = (url: string): boolean => url.includes('avatar-default.png');

export const urlB64ToUint8Array = (base64String: string | any[]) => {
	const padding = '='.repeat((4 - (base64String?.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData?.length);

	for (let i = 0; i < rawData?.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};

export const truncateLongText = (text: string, maxLength: number): string => {
	const THREE_DOTS_LENGTH = 3;
	return text?.length <= maxLength ? text : text?.substr(0, maxLength - THREE_DOTS_LENGTH) + '...';
};

export const getTruncatedChannelName = (channel?: User): string => {
	const MAX_FULL_CHANNEL_NAME_LENGTH = 20;
	return truncateLongText(getChannelName(channel) ?? '', MAX_FULL_CHANNEL_NAME_LENGTH);
};

export const getFirstCharacterUppercase = (text?: string) => {
	if (!text || !text[0]) {
		return '';
	}
	return text[0].toUpperCase();
};

export const camelCase = (text: string): string => {
	return text
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index == 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};

export const replaceWithUnicode = (value: string | undefined): string | undefined => {
	let unescapedValue = value;
	if (unescapedValue && unescapedValue.length > 0) {
		const CHARS = {
			apostrophe: {
				code: `'`,
				symbol: `\u2019`
			}
		};
		Object.values(CHARS).forEach(charData => {
			unescapedValue = unescapedValue?.replace(new RegExp(charData.code, 'g'), charData.symbol);
		});
	}

	return unescapedValue;
};

/** This function periodically calls a test function until either the test
 * returns true or the timeout is reached.
 * - Failures in the test function will be caught and ignored, so that transient
 *   failures don't cause a problem.
 * - setTimeout is used rather than setInterval so that tests don't pile on top
 *   of each other if they run slowly
 *
 * @return Promise<void> a promised which succeeds if the test succeeds before
 * timeout, or fails otherwise */
export const testUntilSuccess = (test: () => Promise<boolean>, msDelay: number, msTimeout: number) => {
	const start = dayjs();
	const isTimeoutExceeded = () => dayjs().subtract(msTimeout, 'ms').isAfter(start);

	return new Promise<void>((resolve, reject) => {
		const run = async () => {
			const result = await test().catch(() => false);
			if (result) {
				resolve();
			} else if (isTimeoutExceeded()) {
				reject();
			} else {
				window.setTimeout(run, msDelay);
			}
		};
		window.setTimeout(run, msDelay);
	});
};

interface PromiseFulfilledResult<T> {
	status: 'fulfilled';
	value: T;
}

interface PromiseRejectedResult {
	status: 'rejected';
	reason: any;
}

type PromiseSettledResult<T> = PromiseFulfilledResult<T> | PromiseRejectedResult;

export const getPromisesResults = (promiseResult: PromiseSettledResult<any>[]) => {
	const fullFilledResults = promiseResult.filter(result => result.status === 'fulfilled');
	return fullFilledResults.map(resolved => {
		if (resolved.status === 'fulfilled') return resolved.value;
	});
};

export const deduplicateArray = <T>(array: T[]): T[] => [...new Set(array)];
