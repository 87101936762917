import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { getCollection } from '../utils';
import { store } from '../../store/store';
import { getAuthRefreshToken, clearAuthTokens, getAuthAccessToken } from '../../domain/auth/authTokenManager';
import { refreshTokenPayload } from '../../store/me/actions';
import { postRefreshToken } from '../authApi/auth';
import { isExpiredRefreshToken, isTokenPotentiallyExpired } from '../authApi/jwt';
import { getAppStatus, handleAppStatusError } from '../statusApi/status';
import { identifyUserStop } from '@content-technology-partners-ltd/shared-flags-react';

export interface ApiError {
	type: string;
	message: string;
}

export enum ApiStatus {
	Success = 'SUCCESS',
	Failure = 'FAIL'
}

export interface ApiResponse {
	status: ApiStatus;
	messages: ApiError[];
}

export const onError = (error: AxiosError) => {
	throw error;
};

const is5xxError = (errorStatus: number) => errorStatus >= 500 && errorStatus <= 599;

export const addToken = (config: InternalAxiosRequestConfig) => {
	const token = getAuthAccessToken();
	if (token) {
		config.headers.set('accesstoken', `${token}`);
	}
	return config;
};

export const onResponseError = (err: AxiosError) => {
	err.response?.status &&
		is5xxError(err.response.status) &&
		getAppStatus().catch(error => handleAppStatusError(error));
};

export const hasError = (errors: ApiError[] | undefined, errorType: string): boolean => {
	return getCollection(errors).some(error => error.type === errorType);
};

export const getBaseApiUrl = () => `${store.getState().endpoints.API_BASE}`;

export const refreshAuthToken = async (): Promise<boolean> => {
	const refreshToken = getAuthRefreshToken();
	if (!refreshToken) {
		return false;
	}
	try {
		await postRefreshToken();
		store.dispatch(refreshTokenPayload());
		return true;
	} catch (e) {
		const httpStatus = (e as any).response ? (e as any).response.status : 0;
		const is4xxError = httpStatus >= 400 && httpStatus < 500;
		if (is4xxError || isExpiredRefreshToken(refreshToken)) {
			clearAuthTokens();
			await identifyUserStop();
			return false;
		}
		throw e;
	}
};

let inFlightRefreshRequest: Promise<boolean> | undefined = undefined;

export const refreshAndAddToken = async (
	config: InternalAxiosRequestConfig,
	authorizationHeaderName = 'accessToken',
	authorizationHeaderValuePrefix = ''
) => {
	let token = getAuthAccessToken();
	if (isTokenPotentiallyExpired(token)) {
		if (!inFlightRefreshRequest) {
			inFlightRefreshRequest = refreshAuthToken();
		}
		await inFlightRefreshRequest;
		token = getAuthAccessToken();
	}
	if (token) {
		config.headers.set(authorizationHeaderName, `${authorizationHeaderValuePrefix}${token}`);
	}
	inFlightRefreshRequest = undefined;
	return config;
};
