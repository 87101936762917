import { isUserLogged } from '../../domain/auth/authManager';
import { GET, POST } from '.';
import { store } from '../../store/store';
import { createPurchaseMetadata } from './utils';

import type { Tariff, Purchase } from '@content-technology-partners-ltd/shared-data-access';

export const isPurchase = (tariffType: Purchase | Tariff): tariffType is Purchase =>
	tariffType.href.includes('/api/public/purchases');

export const isTariff = (tariffType: Purchase | Tariff): tariffType is Tariff =>
	tariffType.href.includes('/api/public/tariffs');

export const fetchUsersChannelPasses = async (): Promise<(Tariff | Purchase)[]> => {
	if (!isUserLogged()) return [];
	const res = await GET('me/purchases?type=CHANNEL_PASS&expand=tariffs');
	return res.data.data.expanded;
};

export const purchaseTariff = async (id: string, recasterUid?: string): Promise<void> => {
	const embeddedMetadata = store.getState().app.embedded;
	await POST('me/purchases', {
		tariff: {
			href: `/api/public/tariffs/${id}`
		},
		recaster: recasterUid ? { id: recasterUid } : undefined,
		metadata: createPurchaseMetadata(embeddedMetadata)
	});
};
