import { PublicApiItem, PublicApiResponseData } from '.';
import { EmbeddedMetadata } from '../../store/app/reducer';

import type { ChannelListing, Tariff, VideoListing } from '@content-technology-partners-ltd/shared-data-access';

type ExpandedListing = VideoListing | ChannelListing | Tariff;

const getExpandedMap = <T extends PublicApiItem>(expanded: T[]) => new Map(expanded.map(item => [item.href, item]));

const isVideoListing = (item: ExpandedListing): item is VideoListing => item.href.includes('/api/public/videos');
const isChannelListing = (item: ExpandedListing): item is ChannelListing => item.href.includes('/partner/channel');
const isTariffListing = (item: ExpandedListing): item is Tariff => item.href.includes('/api/public/tariffs');

export const getExpandedVideoListings = (
	videoResponse: PublicApiResponseData<VideoListing, PublicApiItem, ExpandedListing>
): VideoListing[] => {
	const expandedVideoListing = getExpandedMap(videoResponse.expanded.filter(isVideoListing));
	const expandedChannels = getExpandedMap(videoResponse.expanded.filter(isChannelListing));
	const expandedTariffs = getExpandedMap(videoResponse.expanded.filter(isTariffListing));

	const videoItems = videoResponse.items ?? (videoResponse.item ? [videoResponse.item] : []);
	return (
		videoItems
			.map(item => {
				const expandedItem = expandedVideoListing.get(item.href) || (item as VideoListing);
				const channel: ChannelListing | undefined = expandedChannels.get(expandedItem.channel.href);
				let channelPasses: (Tariff | undefined)[] = [];

				if (channel && channel.channelPasses.length) {
					channelPasses = channel.channelPasses.map(pass => expandedTariffs.get(pass.href));
				}

				return {
					...expandedItem,
					channel: channel
						? {
								...channel,
								channelPasses
						  }
						: undefined
				};
			})
			// Certain pre-partner migration videos do not have channel data - safe to ignore
			.filter(item => item.channel) as VideoListing[]
	);
};

export const getIdFromHref = (href?: string) => href && href.split('/').pop();

export const createPurchaseMetadata = (metadata: EmbeddedMetadata) => {
	return {
		source: location.host,
		...(metadata.channelId && { channelId: metadata.channelId }),
		...(metadata.widgetId && { widgetId: metadata.widgetId })
	};
};
