import { VideoItem, VideoType } from '../../utils/mediaApi/video';
import { ChannelVideoStub } from '../../utils/mediaApi/user';
import { store } from '../../store/store';

const USER_TOKEN_PLACEHOLDER = '{user_token}';

export const getIsBroadcast = (video?: VideoItem | ChannelVideoStub | null): boolean => {
	return video ? +video.videoTypeId === VideoType.BROADCAST : false;
};

export const replaceUserToken = (url: string | undefined): string | undefined => {
	if (!url) return url;

	const userToken = store.getState().me.user_token;

	return url.replace(USER_TOKEN_PLACEHOLDER, userToken);
};
