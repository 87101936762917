import { VideoItem, WatchedVideo } from '../../utils/mediaApi/video';
import { isEmptyObject } from '../../utils/utils';
import { getAlreadyWatchedVideos } from './store/watchedVideosStore';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { PlayedVideo, PlayedVideosMapType } from '../../utils/mediaApi/me';
import { DateSortOrder } from '../../utils/dateUtils';
import { isLoggedAsAdmin } from '../user/userManager';
import { getIsLiveChannel } from '../user/channelManager';

import type { VideoListing } from '@content-technology-partners-ltd/shared-data-access';

export const isAlreadyPurchasedVideo = (video: VideoListing, playedVideos?: PlayedVideosMapType): boolean => {
	if (playedVideos && !isEmptyObject(playedVideos)) {
		return playedVideos[video.id] || false;
	}
	return false;
};

export const isVideoAlreadyWatched = (video?: VideoListing, alreadyWatchedVideos?: WatchedVideo[]): boolean => {
	if (!video) {
		return false;
	}
	if (!alreadyWatchedVideos) {
		return getAlreadyWatchedVideos().some(watchedVideo => watchedVideo.videoUid === video.id);
	}
	return alreadyWatchedVideos.some(watchedVideo => watchedVideo.videoUid === video.id);
};

export const deduplicateVideos = <T>(videos?: T[]): T[] => {
	return uniqBy(videos, 'uid');
};

export const sortPlayedVideosByDate = (videos: PlayedVideo[]): PlayedVideo[] => {
	return videos.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1));
};

export const sortVideosByDate = <T extends { startDate: string | null; created: string }>(
	videos: T[],
	order: DateSortOrder = DateSortOrder.NewestFirst
): T[] => {
	const newestFirst = order === DateSortOrder.NewestFirst;
	return videos.sort((a, b) => {
		const dateA = a.startDate || a.created;
		const dateB = b.startDate || b.created;
		return dayjs(dateA).isAfter(dayjs(dateB)) ? (newestFirst ? -1 : 1) : newestFirst ? 1 : -1;
	});
};

const getVideosFromLiveChannels = (videos: VideoItem[]) => {
	return videos.filter(video => getIsLiveChannel(video.user));
};

const getWithoutAdminVideos = (videos: VideoItem[]) => {
	const SEARCH_WORD_USING_IN_ADMIN_VIDEOS = 'recastTest';
	return videos.filter((video: VideoItem) => !video.name?.includes(SEARCH_WORD_USING_IN_ADMIN_VIDEOS));
};

export const getValidVideos = (videos: VideoItem[]) => {
	const videosFromLiveChannel = getVideosFromLiveChannels(videos);
	return isLoggedAsAdmin() ? videosFromLiveChannel : getWithoutAdminVideos(videosFromLiveChannel);
};
