import { isEmptyObject } from '../../utils/utils';

const PUSH_SUBSCRIPTION_KEY = 'pushSubscription';

type NotificationPermission = 'default' | 'denied' | 'granted';

export const isPushNotificationsSupported = (): boolean => {
	return 'serviceWorker' in navigator && 'PushManager' in window;
};

export const askUserForNotificationsPermission = async (): Promise<NotificationPermission> => {
	return await Notification.requestPermission(permission => permission as NotificationPermission);
};

export const isNotAskedForPushPermission = (): boolean => {
	return isPushNotificationsSupported() ? Notification.permission === 'default' : true;
};

export const isGrantedPushPermission = (): boolean => {
	return isPushNotificationsSupported() ? Notification.permission === 'granted' : false;
};

export const isDeniedPushPermission = (): boolean => {
	return isPushNotificationsSupported() ? Notification.permission === 'denied' : true;
};

export const savePushSubscriptionInStorage = (option: any) => {
	if (!option) return;
	localStorage.setItem(PUSH_SUBSCRIPTION_KEY, JSON.stringify(option));
};

const getPushSubscriptionFromStorage = (): any => {
	return JSON.parse(localStorage.getItem(PUSH_SUBSCRIPTION_KEY) || '{}');
};

export const getPushSubscriptionEndpoint = (): string => {
	const pushFromStorage = getPushSubscriptionFromStorage();
	return pushFromStorage?.endpoint ?? '';
};

export const isPushSubscription = (): boolean => {
	return !isEmptyObject(getPushSubscriptionFromStorage());
};

export const deletePushSubscriptionFromStorage = () => {
	localStorage.removeItem(PUSH_SUBSCRIPTION_KEY);
};
