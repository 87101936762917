import {
	APIResponsePagination,
	Country,
	DELETE,
	formPOST,
	GET,
	GETWithRetries,
	MediaAPIList,
	POST,
	PUT,
	User
} from '.';
import { currencies } from '../../constants/Common';
import * as authApi from '../authApi/user';
import { SocialSignupType } from '../authApi/auth';
import { getLiveChannels } from '../../domain/user/channelManager';
import { isChannelListing } from '../../domain/user/channelListingManager';

import type { ChannelListing, Purchase, Tariff } from '@content-technology-partners-ltd/shared-data-access';

export interface Balance {
	currency: currencies.CST | currencies.GBP;
	units: number;
	negative?: boolean;
	tenths?: number;
	hundredths?: number;
	thousandths: number;
	millionths?: number;
}

export interface Account {
	currencyCode: string;
	accountType: string;
	linkedId: string;
	balance: Balance;
	description: string | null;
}

export interface MeProfile extends User {
	phone: string;
	email: string;
	created: string;
	updated: string;
	reposts: string[];
}

export interface Image {
	description: string;
	id: number;
	imageUrl: string;
	metadata: { size: { h: number; w: number }; format: string; background: string };
	resourceType: string;
	status: string;
}

export enum ChannelStatus {
	DRAFT = 'DRAFT',
	READY = 'READY',
	LIVE = 'LIVE',
	SUSPENDED = 'SUSPENDED',
	DELETED = 'DELETED'
}

export interface Channel {
	uid: string;
	username: string;
	// because media_api doesnt returns consistent objects
	fullName: string;
	name: string;
	description: string;
	partnerId: string;
	featuredVideoIds: string[];
	avatarImage: string;
	avatarImageDetails: Image | null;
	bannerImage: string;
	bannerImageDetails: Image | null;
	followerCount: number;
	status: ChannelStatus;
	ceilingCategoriesIds?: number[];
	channelPasses: Tariff[];
	created: string;
}

export interface PlayedVideo {
	videoId: string;
	date: string;
	isPurchase: boolean;
	isAdvert: boolean;
	isBroadcast: boolean;
}

export interface FeaturedVideo {
	uid: string;
	priority: number;
}

export interface ProfileChannelPasses {
	purchases: Purchase[];
	tariffs: Tariff[];
}

export interface PlayedVideosMapType {
	[key: string]: boolean;
}

export interface Transaction {
	amt: Balance;
	name: string;
	subtitle?: string;
	url?: string;
	balanceAfter: Balance;
	thumbnail?: string;
	timestamp: string;
	type: string;
}

export interface MeAll {
	profile?: MeProfile;
	following?: User[];
	account?: Account;
	playedVideos?: PlayedVideo[];
	playedVideosMap?: PlayedVideosMapType;
	recentlyPlayedVideos: string[];
	transactions?: Transaction[];
	channelPasses?: ProfileChannelPasses;
	watchVideoUid: string;
	currentLocation: string;
	user_token: string;
	channels?: User[];
	notLiveChannels?: User[];
	darkChannels?: User[];
	currentChannel: Channel;
	marketingPreference: number | null;
	socialConnections: SocialSignupType[];
	isPasswordSet: boolean;
}

export enum PostingType {
	DIRECT = 1,
	RECAST = 2
}

export interface LocationData {
	location: string;
	user_token: string;
}

export interface MarketingConsent {
	marketingConsent: number | null;
}

export const getAccount = async (): Promise<Account> => {
	const res = await GET<Account>('me/wallet');
	return res.data.data;
};

export const followUser = async (follower: User | ChannelListing) => {
	const userId = isChannelListing(follower) ? follower.id : follower.uid;
	await POST<MediaAPIList<User>>(`user/follow/${userId}`);
};

export const unfollowUser = async (follower: User | ChannelListing) => {
	const userId = isChannelListing(follower) ? follower.id : follower.uid;
	await DELETE<MediaAPIList<User>>(`user/follow/${userId}`);
};

export const getPlayedVideos = async () => {
	const res = await GET<PlayedVideo[]>('user/playedVideos');
	return res.data.data;
};

export const transformPlayedVideosToMap = (playedVideos: PlayedVideo[]): PlayedVideosMapType => {
	return Object.assign({}, ...playedVideos.map(video => ({ [video.videoId]: true })));
};

/** Get a fully hydrated profile, including followed users and account info */
export const getProfile = async () => {
	const homeProfile = (await GET<MeAll>('me/homeProfile')).data.data;

	const userContacts = await authApi.getUserContactMethods();
	return {
		...homeProfile,
		profile: {
			...homeProfile.profile,
			phone: userContacts.phone,
			email: userContacts.email
		},
		following: getLiveChannels(homeProfile.following),
		playedVideosMap: transformPlayedVideosToMap(homeProfile.playedVideos || [])
	} as MeAll;
};

export const updateProfile = async (userObj: Partial<User>) => {
	const res = await formPOST('user/profile', userObj);
	return res.data.data;
};

export const getUserTransactions = async () => {
	const res = await GET<Transaction>('user/transactions');
	return res.data.data;
};

export const getSupportedCountries = async (): Promise<Country[]> => {
	const res = await GET<APIResponsePagination<Country>>('countries');
	return res.data.data.items;
};

export const getCurrentLocation = async (): Promise<LocationData> => {
	const res = await GETWithRetries<LocationData>('location');
	return res.data.data;
};

export const getMarketingConsent = async (): Promise<MarketingConsent> => {
	const res = await GET<MarketingConsent>('marketing/preference');
	return res.data.data;
};

export const updateMarketingConsent = async (marketingConsent: number | null) => {
	const res = await PUT('marketing/preference', {
		marketingConsent
	});
	return res.data.data;
};
