import { Country } from '../utils/mediaApi';

export enum currencies {
	GBP = '£',
	CST = 'CST'
}

export const SUPPORT_EMAIL = 'helpme@recast.tv';
export const ZENDESK_SUPPORT_EMAIL = 'support@recast.zendesk.com';
export const COPYRIGHT_EMAIL = 'copyright@recast.tv';
export const ABUSE_EMAIL = 'abuse@recast.tv';

export const EMAIL_BODY_BREAK_LINE = '%0D%0A';

export const LANDING_PAGE_URL = 'https://recast.tv';

export const HELP_AND_FAQS_URL = `${LANDING_PAGE_URL}/for-fans/help-and-faqs`;

export const AVAILABLE_COUNTRIES_URL = `${LANDING_PAGE_URL}/available-countries`;

export const EARNING_POLICY_URL = `${LANDING_PAGE_URL}/publisher-earning-policy#sharing-a-video`;

export const TERMS_URL = LANDING_PAGE_URL + '/terms-and-conditions';
export const PRIVACY_POLICY_URL = LANDING_PAGE_URL + '/privacy-policy';

export const REFERRER_URL_PARAM_NAME = 'referrer';
export const VIDEO_ID_URL_PARAM_NAME = 'videoId';
export const USER_ID_URL_PARAM_NAME = 'userId';
export const EMAIL_URL_PARAM_NAME = 'email';
export const RESET_PASSWORD_TOKEN_URL_PARAM_NAME = 'token';
export const MIN_PASSWORD_LENGTH = 7;

export enum UTM_TAGS {
	source = 'utm_source',
	medium = 'utm_medium',
	campaign = 'utm_campaign',
	term = 'utm_term',
	content = 'utm_content'
}

export enum MarketingConsentType {
	optedOut = 1,
	optedIn = 2
}

export const SERVER_GENERAL_ERROR = 'Server error, please try again later';

export const IS_LOGGED_IN_TO_APP_KEY = 'isLoggedInToApp';

export const IS_PURCHASE_GUIDANCE_VIEWED_KEY = 'isPurchaseGuidanceViewed';
export const PURCHASE_GUIDANCE_VIEWED_COUNTER = 'purchaseGuidanceViewedCounter';

export const IS_SUGGEST_CHANNEL_PASS_VIEWED_KEY = 'isSuggestChannelPassViewed';

export const REDIRECT_URL_AFTER_LOGIN_KEY = 'redirectUrlAfterLogin';

export const VIDEO_ID_TO_BUY_KEY = 'videoIdToBuy';

export const VIDEO_ID_TO_FOLLOW_KEY = 'videoIdToFollow';
export const USER_ID_TO_FOLLOW_KEY = 'userIdToFollow';

export const WATCHED_VIDEOS_KEY = 'alreadyWatchedVideos';

export const WATCHED_TRAILERS_KEY = 'alreadyWatchedTrailers';

export const ESPORTS_CATEGORY_NAME_LOWERCASE = 'esports';
export const GENERAL_CATEGORY_NAME_LOWERCASE = 'general';

export const CATEGORIES_FILTER_ORDER_KEY = 'categoriesFilterOrder';
export const CHANNELS_FILTER_ORDER_KEY = 'channelsFilterOrder';

export const FIRST_TIME_LOGIN_DATE_KEY = 'firstTimeLoginDate';

export const IS_FULL_SCREEN_MODE_KEY = 'isFullScreenMode';

export const IS_USING_INITIAL_BALANCE_KEY = 'isUsingInitialBalance';
export const BALANCE_KEY = 'balance';

export const REFERRER_KEY = 'referrer';
export const REFERRED_WIDGET_ID_KEY = 'referredWidgetId';
export const NEW_SOCIAL_SIGNUP_PARAM_NAME = 'newSocialSignup';
export const VIDEO_REFERRER_KEY = 'videoReferrer';
export const REFERRED_VIDEO_UID_KEY = 'referredVideoUid';
export const EMAIL_KEY = 'email';

export const AUTH_TOKENS_KEY = 'authTokens';

export const LOGGED_BROADCAST_CHANNEL = 'logged-channel';
export const FAN_APP_BROADCAST_CHANNEL = 'fan-app-channel';

export const LOGGED_IN_EVENT = 'logged-in';

export const LOGGED_OUT_EVENT = 'logged-out';

export const READ_NOTIFICATIONS_KEY = 'readNotifications';
export const LAST_READ_NOTIFICATIONS_DATE_KEY = 'lastReadNotificationsDate';

export const IS_SIDEBAR_COLLAPSED_KEY = 'isSidebarCollapsed';

export const MIN_CATEGORIES_NUMBER = 1;

export const MAX_PIXELS_PER_SIDE_FOR_UPLOAD_PHOTO = 640;

export const SOCIAL_VIDEO_PREVIEW_DURATION_IN_SECONDS = 5;
export const SOCIAL_VIDEO_PREVIEW_DURATION_IN_MILLISECONDS = SOCIAL_VIDEO_PREVIEW_DURATION_IN_SECONDS * 1000;

export const PREMIUM_VIDEO_PREVIEW_DURATION_IN_SECONDS = 15;
export const PREMIUM_VIDEO_PREVIEW_DURATION_IN_MILLISECONDS = PREMIUM_VIDEO_PREVIEW_DURATION_IN_SECONDS * 1000;

export enum TIME_TO_HIDE_PLAYER_UI_IN_MILLISECONDS {
	MOBILE = 4 * 1000,
	DESKTOP = 2 * 1000
}

export const DISPLAY_VIDEO_DURATION_ADJUSTMENT = 1;

export const VERIFICATION_CODE_LENGTH = 5;

export const PLAYER_RECTANGULAR_VIDEO_RATIO = 9 / 16;

export const PLAYER_SQUARE_VIDEO_RATIO = 1;

export const MODAL_CONTAINER_ID = 'modalsContainer';

export const CONTENT_CONTAINER_ID = 'content-container';

export const STORAGE_EVENT_LISTENER = 'storage';

export const MAX_VIDEO_NAME_LENGTH = 85;

export const VIDEO_DESCRIPTION_TRUNCATED_NUMBER_OF_LINES = 2;

export const NO_SWIPING_CLASS = 'vjs-control';

export const SWIPE_INITIAL_THRESHOLD_PX = 10;

export const RESIZE_THROTTLE_MS = 100;
export const SCROLL_THROTTLE_MS = 100;

export const LARGE_SCREEN_WIDTH_PX = 992;
export const SMALL_SCREEN_WIDTH_PX = 576;
export const XXS_SCREEN_WIDTH_PX = 367;

export const VIDEO_CARD_WIDTH_PX = 328;
export const VIDEO_CARD_WIDTH_SMALL_PX = 260;
export const VIDEO_CARD_THUMBNAIL_HEIGHT_PX = 184;
export const VIDEO_CARD_THUMBNAIL_HEIGHT_SMALL_PX = 146;

export const FOR_YOU_FILTER_ID = -1;
export const POPULAR_FILTER_ID = -4;
export const FOLLOWED_FILTER_ID = -5;
export const ALL_FILTER_ID = -6;
export const MY_CHANNELS_FILTER_ID = -8;

export const I18N_CATEGORY_KEY_PREFIX = 'filter.interest.';

export const RECENTLY_PUBLISHED_DAYS = 28;

export const ONLINE_EVENT = 'online';
export const OFFLINE_EVENT = 'offline';

export const ALL_BALANCE_ANIMATIONS_DELAY = 400;
export const RING_ANIMATION_DELAY = ALL_BALANCE_ANIMATIONS_DELAY + 300;
export const TIME_TO_DISABLE_ANIMATIONS = RING_ANIMATION_DELAY + 1000;

export const WATCH_BUTTON_COIN_ANIMATION_TIME_IN_MS = 1500;

export const BROADCAST_CREATED_BY_LABEL = 'Live';

export const GB_COUNTRY_CODE = 'GB';

export const UNRESTRICTED_REGION = { name: 'All Regions', code: '**', enabled: 1 } as Country;

export const ALL_TAG_ID = 0;
export const LATEST_TAG_FILTER_KEY = 'latestTagFilter';

export const ALLOWED_UPLOAD_IMAGE_FORMATS = 'image/png,image/x-png,image/jpeg,.heif,.heic';

export enum ORIENTATION_TYPE {
	'LANDSCAPE' = 'landscape',
	'PORTRAIT' = 'portrait'
}

export enum DisplayPosition {
	'LEFT' = 'LEFT',
	'RIGHT' = 'RIGHT'
}

export const WIDESCREEN_MIN_WIDTH_PX = 768; // NOTE: Corresponds to screen-md in breakpoints.scss
export const WIDESCREEN_RESIZE_THROTTLE_MS = 100;
export const SCREEN_XXS_WIDTH_PX = 367; // NOTE: Corresponds to screen-xxs in breakpoints.scss

export enum ProgressStatus {
	INIT = 'INIT',
	IN_PROGRESS = 'IN_PROGRESS',
	FINISH = 'FINISH'
}

export enum DateFormat {
	API = 'YYYY-MM-DD HH:mm:ss',
	DURATION_UI = 'HH:mm:ss',
	SHORT_DURATION_UI = 'm:ss',
	READABLE_DURATION_MINUTES_UI = 'm[m] s[s]',
	READABLE_DURATION_HOURS_UI = 'H[h] m[m]',
	READABLE_DURATION_MINUTES_SHORTER_UI = 'mm:ss',
	READABLE_DURATION_HOURS_SHORTER_UI = 'HH:mm',
	READABLE_DURATION_HOURS_WITH_SECONDS_SHORTER_UI = 'HH:mm:ss',
	YEAR_MONTH_DAY = 'YYYY-MM-DD',
	HOUR_MIN = 'HH:mm',
	DATE_AND_TIME_UI = 'D MMM HH:mm',
	DATE_AND_TIME_WALLET = 'DD MMM YYYY HH:mm',
	DATE_AND_MONTH_DESCRIPTIVE = 'dddd DD MMMM',
	DATE_AND_MONTH_DESCRIPTIVE_SHORT = 'ddd DD MMM',
	DAY_ORDINAL_AND_MONTH = 'Do MMMM'
}

export enum HTTP_STATUS_CODES {
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	TIMEOUT = 408,
	CONFLICT = 409,
	TOO_MANY_REQUESTS = 429,
	INTERNAL_ERROR = 500,
	TEMPORARY_UNAVAILABLE = 503,
	GATEWAY_TIMEOUT = 504
}

export interface BuyVideoHandle {
	watchVideo: () => void;
}

export enum QueryCacheKey {
	LIVE_VIDEOS = 'liveVideo',
	VIDEOS = 'videos',
	SINGLE_VIDEO = 'singleVideo',
	ME_PROFILE = 'meProfile',
	USER_VIDEOS = 'userVideos',
	FEATURED_VIDEOS_IDS = 'featuredVideosIds',
	FEATURED_VIDEOS_SUFFIX = 'featuredVideos',
	CHANNEL_SHARED_VIDEOS_SUFFIX = 'channelSharedVideos',
	CATEGORIES = 'categories',
	FOLLOWED_CATEGORIES_HREFS = 'followedCategoriesHrefs',
	CURRENTLY_LIVE_VIDEOS = 'currentlyLiveVideos',
	CHANNELS = 'channels',
	PROFILE_DATA = 'profileData',
	USER_BONUSES = 'userBonuses',
	PUBLISHER_DATA_SHARE = 'publisherDataShare',
	CHANNEL_DETAILS = 'channelDetails',
	COMMENTS = 'comments',
	USER_CHANNEL_PASSES = 'userChannelPasses',
	CHANNEL_PASSES = 'channelPasses',
	WIDGET_DETAILS = 'widgetDetails',
	AD_VALUE = 'adValue',
	CHANNEL_PASS_PROFILES = 'channelPassProfiles',
	CHANNEL_AFFILIATES = 'channelAffiliates'
}

export const VIDEOS_CACHE_STALE_TIME = 5 * 1000 * 60;
export const CHANNELS_CACHE_STALE_TIME = VIDEOS_CACHE_STALE_TIME;

export const LIVE_VIDEOS_FETCH_INTERVAL = 120 * 1000;

export enum PromotionTypeId {
	//for backward compatibility
	paidPlacement = 1,
	productPromotion = 2,
	embeddedAdvertising = 3,
	sponsoredContent = 4,
	otherPromotion = 5,

	//active in publisher app
	brandedPaidOrAdvertorials = 6,
	preRollOrAdBreaks = 7,
	sponsored = 8,
	productPlacement = 9,
	selfPromotional = 10,
	affiliateMarketing = 11
}

export enum PromotionType {
	paidPlacement = 'paidPlacement',
	productPromotion = 'productPromotion',
	embeddedAdvertising = 'embeddedAdvertising',
	sponsoredContent = 'sponsoredContent',
	otherPromotion = 'otherPromotion',
	brandedPaidOrAdvertorials = 'brandedPaidOrAdvertorials',
	preRollOrAdBreaks = 'preRollOrAdBreaks',
	sponsored = 'sponsored',
	productPlacement = 'productPlacement',
	selfPromotional = 'selfPromotional',
	affiliateMarketing = 'affiliateMarketing'
}

export const PROMOTION_TYPE_NAME: Record<PromotionType, string> = {
	[PromotionType.paidPlacement]: 'Paid placement',
	[PromotionType.productPromotion]: 'Product promotion',
	[PromotionType.embeddedAdvertising]: 'Embedded advertising',
	[PromotionType.sponsoredContent]: 'Sponsored content',
	[PromotionType.otherPromotion]: 'Other promotion',

	[PromotionType.brandedPaidOrAdvertorials]: 'Branded content, paid partnerships or advertorials',
	[PromotionType.preRollOrAdBreaks]: 'Pre-roll ads or ad breaks',
	[PromotionType.sponsored]: 'Sponsored content',
	[PromotionType.productPlacement]: 'Product placement',
	[PromotionType.selfPromotional]: 'Self-promotional',
	[PromotionType.affiliateMarketing]: 'Affiliate marketing'
};

export const NATIVE_SOURCE_URL_PARAM_KEY = 'source';
export const REDIRECTED_FROM_NATIVE_SOURCE_URL_PARAM_KEY = 'fromSource';
export const NATIVE_SOURCE_URL_PARAM_VALUE_ANDROID = 'android-native';
export const IS_ANDROID_NATIVE_SESSION_KEY = 'isAndroidNative';
export const IS_REDIRECTED_FROM_ANDROID_NATIVE_SESSION_KEY = 'isRedirectedFromAndroidNative';

export const NEXT_INSTALL_PROMPT_DATE_KEY = 'nextInstallPromptDate';
export const INSTALL_PROMPT_VIEW_COUNT_KEY = 'installPromptViewCount';
export const MAX_PROMPT_VIEW_COUNT = 3;
export const DAYS_TO_WAIT_AFTER_PROMPT_SHOWN = 7;
export const DAYS_TO_WAIT_BETWEEN_PUSH_AND_INSTALL_PROMPTS = 1;

export const NATIVE_REDIRECT_URL_SCHEME = 'recast-app://';
export const ANDROID_NATIVE_INTERNAL_ROUTE = 'native-app';

export const UP_NEXT_SECONDARY_VIDEOS_COUNT = 2;

export const ASPECT_RATIO = 0.5625; // 16:9

export const IS_MOBILE_NEW_CATEGORY_TOOLTIP_VIEWED_KEY = 'isMobileNewCategoryTooltipViewed';
export const IS_DATA_PROFILE_BONUS_BANNER_VIEWED_KEY = 'isDataProfileBonusBannerViewed';

export const REFRESH_TOKEN_LIFETIME_IN_SECONDS = 7776000;
export const FIVE_MINUTES_IN_SECONDS = 300;
export const DRM_MEDIA_ERROR_CODE = 5;
export const MEDIA_ERR_SRC_NOT_SUPPORTED = 4;

export const BONUS_CAST_CREDITS_AFTER_SIGN_UP_25 = 25;
export const DAYS_TO_EXPIRY_SIGN_UP_CASTS_CREDITS = 14;

export const DATA_PROFILE_BONUS_CASTS = 10;

export const BONUS_CAST_CREDITS_AFTER_REFER_100 = 100;

export type MessageType = 'GeneralError' | 'InvalidCredentialsError';

export type MessageSeverity = 'ERROR' | 'WARNING';

export interface ApiMessage {
	type: MessageType;
	message: string;
	severity: MessageSeverity;
}

export const FULL_PARENT_URL = 'fullParentUrl';
