import axios from 'axios';
import { getLoggedUserId } from '../../domain/auth/authManager';
import { NotificationsPreferences, NotificationType, NewVideoNotificationSubscription } from './types';
import { getPushSubscriptionEndpoint } from '../../domain/notifications/pushNotificationManager';
import { refreshAndAddToken, getBaseApiUrl, onError } from '../api/api';
import { getAuthAccessToken } from '../../domain/auth/authTokenManager';
import {
	reduceNotificationPreferencesToType,
	getSettingsWithoutEmptyString
} from '../../domain/notifications/notificationsManager';

const PREFERENCES_URL_SUFFIX = 'preferences/';
const SUBSCRIBE_VIDEO_URL_SUFFIX = 'subscription/new-video/';
const SUBSCRIBE_LIVE_STREAM_URL_SUFFIX = 'subscription/livestream/';

const notificationClient = axios.create();

notificationClient.interceptors.request.use(refreshAndAddToken, onError);

const getNotificationsApiUrl = (suffix: string): string => `${getBaseApiUrl()}/notifications/${suffix}`;

export const getNotificationSettings = async () => {

	const res = await notificationClient.get<NotificationsPreferences>(
		getNotificationsApiUrl(PREFERENCES_URL_SUFFIX)
	);

	return getSettingsWithoutEmptyString(res.data);
};

export const saveNotificationSettings = async (settings: NotificationsPreferences) => {
	const res = await notificationClient.put(
		getNotificationsApiUrl(PREFERENCES_URL_SUFFIX) + getLoggedUserId(),
		settings
	);
	return res.data.data;
};

export const subscribeNewVideo = async (userId: string, omitReductionToType?: boolean) => {
	!omitReductionToType && (await reduceNotificationPreferencesToType(NotificationType.NEW_VIDEO));
	const res = await notificationClient.post(getNotificationsApiUrl(SUBSCRIBE_VIDEO_URL_SUFFIX), {
		publisherUid: userId
	});
	return res.data.data;
};

export const subscribeLiveStream = async (livestreamId: string) => {
	await reduceNotificationPreferencesToType(NotificationType.LIVE_EVENT);
	const res = await notificationClient.post(getNotificationsApiUrl(SUBSCRIBE_LIVE_STREAM_URL_SUFFIX), {
		livestreamId: livestreamId
	});
	return res.data.data;
};

export const unsubscribeNewVideo = async (userId: string) => {
	const res = await notificationClient.delete(getNotificationsApiUrl(SUBSCRIBE_VIDEO_URL_SUFFIX), {
		headers: { accessToken: getAuthAccessToken() },
		data: { publisherUid: userId }
	});
	return res.data.data;
};

export const areUsersSubscribed = async (userIds: string[]): Promise<NewVideoNotificationSubscription[]> => {
	const res = await notificationClient.post(getNotificationsApiUrl(SUBSCRIBE_VIDEO_URL_SUFFIX + 'exists'), {
		publisherUids: userIds
	});
	return res.data.data.subscriptions;
};

export const getKeyForNotification = async () => {
	const res = await notificationClient.get(getNotificationsApiUrl('push/key'));
	return res.data.data.key;
};

export const subscribePushNotification = async (subscription: string) => {
	return await notificationClient.post(getNotificationsApiUrl('push/subscription'), JSON.stringify(subscription), {
		headers: { 'content-type': 'application/json' }
	});
};

export const deactivatePushNotification = async () => {
	const pushSubscriptionEndpoint = getPushSubscriptionEndpoint();
	if (!pushSubscriptionEndpoint) return;

	return await notificationClient.delete(getNotificationsApiUrl('push/subscription'), {
		data: { endpoint: pushSubscriptionEndpoint },
		headers: { 'content-type': 'application/json' }
	});
};
