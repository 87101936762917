import { AppThunk } from '../../store/store';
import {
	OneTrustCookieAgreement,
	SelectionForPlay,
	SignUpModal,
	SignUpFollowUpData,
	ScreenSize,
	PurchaseCastsWizard,
	ReplayProcessing,
	ReplayNotFound,
	ChannelPassesWizard,
	CommentModal,
	ChannelPassModal,
	EmbeddedMetadata
} from './reducer';
import * as meApi from '../../utils/mediaApi/me';
import { Country, User } from '../../utils/mediaApi';
import type { ChannelListing, VideoListing } from '@content-technology-partners-ltd/shared-data-access';

export interface ShowTopModalAction {
	type: 'SHOW_TOP_MODAL';
	payload: string;
}

export interface ClearTopModalAction {
	type: 'CLEAR_TOP_MODAL';
}

export interface ToggleInviteModalVisibilityAction {
	type: 'TOGGLE_INVITE_MODAL_VISIBILITY_ACTION';
	payload: boolean;
}

export interface ToggleAuthErrorModalAction {
	type: 'TOGGLE_AUTH_ERROR_MODAL';
	payload: boolean;
}

export interface SetSearchValueAction {
	type: 'SET_SEARCH_VALUE';
	payload: string;
}

export interface ToggleSearchingAction {
	type: 'TOGGLE_SEARCHING';
	payload: boolean;
}

export interface SetIsOfflineAction {
	type: 'SET_IS_OFFLINE';
	payload: boolean;
}

export interface SetExploreFilterIdAction {
	type: 'SET_EXPLORE_FILTER_ID';
	payload: number;
}

export interface ToggleHomepageVideosMutedAction {
	type: 'TOGGLE_HOMEPAGE_VIDEOS_MUTED';
	payload: boolean;
}

export interface ShowNotSupportedRegionModalAction {
	type: 'SHOW_NOT_SUPPORTED_REGION_MODAL';
}

export interface HideNotSupportedRegionModalAction {
	type: 'HIDE_NOT_SUPPORTED_REGION_MODAL';
}

export interface setChannelNotificationsModalAction {
	type: 'SET_CHANNEL_NOTIFICATIONS_MODAL';
	payload: string;
}

export interface clearChannelNotificationsModalAction {
	type: 'CLEAR_CHANNEL_NOTIFICATIONS_MODAL';
}

export interface GetSupportedCountries {
	type: 'GET_SUPPORTED_COUNTRIES';
	payload: Country[];
}

export interface SetHasWatchedLatestPreview {
	type: 'SET_HAS_WATCHED_LATEST_PREVIEW';
	payload: boolean;
}

export interface ShowFollowNotificationModalWithUser {
	type: 'SHOW_FOLLOW_NOTIFICATION_MODAL_WITH_USER';
	payload: User | ChannelListing;
}

export interface HideFollowNotificationModal {
	type: 'HIDE_FOLLOW_NOTIFICATION_MODAL';
}

export interface ShowSupportModalAction {
	type: 'SHOW_SUPPORT_MODAL';
	payload: string;
}

export interface HideSupportModalAction {
	type: 'HIDE_SUPPORT_MODAL';
}

export interface SetLoginEmailValueAction {
	type: 'SET_LOGIN_EMAIL_VALUE';
	payload: string;
}

export interface SelectVideoForPlay {
	type: 'SELECT_VIDEO_FOR_PLAY';
	payload: SelectionForPlay;
}

export interface SetSharedVideo {
	type: 'SET_SHARED_VIDEO';
	payload: VideoListing | undefined;
}

export interface ClearSharedVideo {
	type: 'CLEAR_SHARED_VIDEO';
}

export interface ClearVideoSelectionForPlay {
	type: 'CLEAR_VIDEO_SELECTION_FOR_PLAY';
}

export interface ToggleChannelPickerVisibility {
	type: 'TOGGLE_CHANNEL_PICKER_VISIBILITY';
	payload: boolean;
}

export interface SetSignUpModal {
	type: 'SET_SIGN_UP_MODAL';
	payload: SignUpModal;
}

export interface SetSuggestChannelPassModal {
	type: 'SET_SUGGEST_CHANNEL_PASS_MODAL';
	payload: ChannelPassModal;
}

export interface SetSignUpFollowUp {
	type: 'SET_SIGN_UP_FOLLOW_UP';
	payload: SignUpFollowUpData;
}

export interface ClearSignUpFollowUp {
	type: 'CLEAR_SIGN_UP_FOLLOW_UP';
}

export interface SetIsPurchasingVideo {
	type: 'SET_IS_PURCHASING_VIDEO';
	payload: boolean;
}

export interface ToggleRegistrationUnavailableModal {
	type: 'TOGGLE_REGISTRATION_UNAVAILABLE_MODAL';
	payload: boolean;
}

export interface SetIsLoggedOutPreviewComplete {
	type: 'SET_IS_LOGGED_OUT_PREVIEW_COMPLETE';
	payload: boolean;
}

export interface UpdateCookieAgreement {
	type: 'UPDATE_COOKIE_AGREEMENT';
	payload: OneTrustCookieAgreement;
}

export interface UpdateCookieAgreement {
	type: 'UPDATE_COOKIE_AGREEMENT';
	payload: OneTrustCookieAgreement;
}

export interface SetIsAutoplayEnabled {
	type: 'SET_IS_AUTOPLAY_ENABLED';
	payload: boolean;
}

export interface ToggleRecastPublishModal {
	type: 'TOGGLE_RECAST_PUBLISH_MODAL';
	payload: boolean;
}

export interface SetIsSidebarMaximised {
	type: 'SET_IS_SIDEBAR_MAXIMISED';
	payload: boolean;
}

export interface SetScreenSize {
	type: 'SET_SCREEN_SIZE';
	payload: ScreenSize;
}

export interface ShowPurchaseCastsWizard {
	type: 'SHOW_PURCHASE_CASTS_WIZARD';
	payload: PurchaseCastsWizard;
}

export interface ShowChannelPassesWizard {
	type: 'SHOW_CHANNEL_PASSES_WIZARD';
	payload: ChannelPassesWizard;
}

export interface SetIsMaintenanceMode {
	type: 'SET_IS_MAINTENANCE';
	payload: boolean;
}

export interface ToggleWelcomeToRecastModal {
	type: 'TOGGLE_WELCOME_TO_RECAST_MODAL';
	payload: boolean;
}

export interface SetIsNewSocialSignup {
	type: 'SET_IS_NEW_SOCIAL_SIGNUP';
	payload: boolean;
}

export interface ToggleVideoNotFoundModal {
	type: 'TOGGLE_VIDEO_NOT_FOUND_MODAL';
	payload: boolean;
}

export interface ToggleUnavailableInUserLocationModal {
	type: 'TOGGLE_UNAVAILABLE_IN_USER_LOCATION_MODAL';
	payload: boolean;
}

export interface SetIsEditProfileModalVisible {
	type: 'SET_IS_EDIT_PROFILE_MODAL_VISIBLE';
	payload: boolean;
}

export interface SetIsSettingsModalVisible {
	type: 'SET_IS_SETTINGS_MODAL_VISIBLE';
	payload: boolean;
}

export interface SetIsDataProfileBonusBannerVisible {
	type: 'SET_IS_DATA_PROFILE_BONUS_BANNER_VISIBLE';
	payload: boolean;
}

export interface ToggleReplayProcessingModal {
	type: 'TOGGLE_REPLAY_PROCESSING_MODAL';
	payload: ReplayProcessing;
}

export interface ToggleReplayNotFoundModal {
	type: 'TOGGLE_REPLAY_NOT_FOUND_MODAL';
	payload: ReplayNotFound;
}

export interface ToggleCommentModal {
	type: 'TOGGLE_COMMENT_MODAL';
	payload: CommentModal;
}

export interface SetEmbeddedMetadata {
	type: 'SET_EMBEDDED_METADATA';
	payload: EmbeddedMetadata;
}

export interface ToggleEmbeddedOverlay {
	type: 'TOGGLE_EMBEDDED_OVERLAY';
	payload: boolean;
}

export interface ShowEmbeddedOverlay {
	type: 'pop-up/already-opened';
}

export interface ToggleEmbeddedWebviewOverlay {
	type: 'TOGGLE_EMBEDDED_WEBVIEW_OVERLAY';
	payload: boolean;
}

export interface ToggleEmbeddedCookiesOverlay {
	type: 'TOGGLE_EMBEDDED_COOKIES_OVERLAY';
	payload: boolean;
}

export type AppActions =
	| ShowTopModalAction
	| ClearTopModalAction
	| ToggleInviteModalVisibilityAction
	| ToggleAuthErrorModalAction
	| SetSearchValueAction
	| ToggleSearchingAction
	| SetIsOfflineAction
	| ToggleHomepageVideosMutedAction
	| SetExploreFilterIdAction
	| ShowNotSupportedRegionModalAction
	| HideNotSupportedRegionModalAction
	| setChannelNotificationsModalAction
	| clearChannelNotificationsModalAction
	| GetSupportedCountries
	| SetHasWatchedLatestPreview
	| ShowFollowNotificationModalWithUser
	| HideFollowNotificationModal
	| ShowSupportModalAction
	| HideSupportModalAction
	| SetLoginEmailValueAction
	| SelectVideoForPlay
	| ClearVideoSelectionForPlay
	| ToggleChannelPickerVisibility
	| SetSignUpModal
	| SetSuggestChannelPassModal
	| SetSignUpFollowUp
	| ClearSignUpFollowUp
	| SetIsPurchasingVideo
	| SetSharedVideo
	| ClearSharedVideo
	| ToggleRegistrationUnavailableModal
	| SetIsLoggedOutPreviewComplete
	| UpdateCookieAgreement
	| SetIsAutoplayEnabled
	| ToggleRecastPublishModal
	| SetIsSidebarMaximised
	| SetScreenSize
	| ShowPurchaseCastsWizard
	| ShowChannelPassesWizard
	| SetIsMaintenanceMode
	| ToggleWelcomeToRecastModal
	| SetIsNewSocialSignup
	| ToggleVideoNotFoundModal
	| ToggleUnavailableInUserLocationModal
	| SetIsEditProfileModalVisible
	| SetIsSettingsModalVisible
	| SetIsDataProfileBonusBannerVisible
	| ToggleReplayProcessingModal
	| ToggleReplayNotFoundModal
	| ToggleCommentModal
	| ShowEmbeddedOverlay
	| ToggleEmbeddedOverlay
	| ToggleEmbeddedWebviewOverlay
	| ToggleEmbeddedCookiesOverlay
	| SetEmbeddedMetadata;

export const showTopModal = (message: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_TOP_MODAL',
			payload: message
		});
	};
};

export const clearTopModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'CLEAR_TOP_MODAL'
		});
	};
};

export const toggleAuthErrorModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_AUTH_ERROR_MODAL',
			payload: isVisible
		});
	};
};

export const toggleInviteModalVisibility = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_INVITE_MODAL_VISIBILITY_ACTION',
			payload: isVisible
		});
	};
};

export const setSearchValue = (value: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SEARCH_VALUE',
			payload: value
		});
	};
};

export const toggleSearching = (value: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_SEARCHING',
			payload: value
		});
	};
};
export const setIsOffline = (value: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_OFFLINE',
			payload: value
		});
	};
};
export const setExploreFilterId = (value: number): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_EXPLORE_FILTER_ID',
			payload: value
		});
	};
};

export const toggleHomepageVideosMuted = (isMuted: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_HOMEPAGE_VIDEOS_MUTED',
			payload: isMuted
		});
	};
};

export const showNotSupportedRegionModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_NOT_SUPPORTED_REGION_MODAL'
		});
	};
};

export const hideNotSupportedRegionModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'HIDE_NOT_SUPPORTED_REGION_MODAL'
		});
	};
};

export const setChannelNotificationsModal = (userId: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_CHANNEL_NOTIFICATIONS_MODAL',
			payload: userId
		});
	};
};

export const clearChannelNotificationsModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'CLEAR_CHANNEL_NOTIFICATIONS_MODAL'
		});
	};
};

export const getSupportedCountries = (): AppThunk => {
	return async dispatch => {
		dispatch({
			type: 'GET_SUPPORTED_COUNTRIES',
			payload: await meApi.getSupportedCountries()
		});
	};
};

export const setHasWatchedLatestPreview = (hasWatchedLatestPreview: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_HAS_WATCHED_LATEST_PREVIEW',
			payload: hasWatchedLatestPreview
		});
	};
};

export const showFollowNotificationModalWithUser = (user: User | ChannelListing): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_FOLLOW_NOTIFICATION_MODAL_WITH_USER',
			payload: user
		});
	};
};

export const hideFollowNotificationModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({ type: 'HIDE_FOLLOW_NOTIFICATION_MODAL' });
	};
};

export const showSupportModal = (message: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_SUPPORT_MODAL',
			payload: message
		});
	};
};

export const hideSupportModal = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'HIDE_SUPPORT_MODAL'
		});
	};
};

export const setLoginEmailValue = (value: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_LOGIN_EMAIL_VALUE',
			payload: value
		});
	};
};

export const selectVideoForPlay = (selection: SelectionForPlay): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SELECT_VIDEO_FOR_PLAY',
			payload: selection
		});
	};
};

export const clearVideoSelectionForPlay = (): AppThunk => {
	return (dispatch): void => {
		dispatch({ type: 'CLEAR_VIDEO_SELECTION_FOR_PLAY' });
	};
};

export const toggleChannelPickerVisibility = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_CHANNEL_PICKER_VISIBILITY',
			payload: isVisible
		});
	};
};

export const setSignUpModal = (modal: SignUpModal): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SIGN_UP_MODAL',
			payload: modal
		});
	};
};

export const setSuggestChannelPassModal = (config: ChannelPassModal): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SUGGEST_CHANNEL_PASS_MODAL',
			payload: config
		});
	};
};

export const setSignUpFollowUp = (followUpData: SignUpFollowUpData): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SIGN_UP_FOLLOW_UP',
			payload: followUpData
		});
	};
};

export const clearSignUpFollowUp = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'CLEAR_SIGN_UP_FOLLOW_UP'
		});
	};
};

export const setIsPurchasingVideo = (isPurchasing: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_PURCHASING_VIDEO',
			payload: isPurchasing
		});
	};
};

export const setSharedVideo = (video?: VideoListing): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SHARED_VIDEO',
			payload: video
		});
	};
};

export const clearSharedVideo = (): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'CLEAR_SHARED_VIDEO'
		});
	};
};

export const toggleRegistrationUnavailableModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_REGISTRATION_UNAVAILABLE_MODAL',
			payload: isVisible
		});
	};
};

export const setIsLoggedOutPreviewComplete = (value: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_LOGGED_OUT_PREVIEW_COMPLETE',
			payload: value
		});
	};
};

export const updateCookieAgreement = (cookieAgreement: OneTrustCookieAgreement): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'UPDATE_COOKIE_AGREEMENT',
			payload: cookieAgreement
		});
	};
};

export const setIsAutoplayEnabled = (isAutoplayEnabled: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_AUTOPLAY_ENABLED',
			payload: isAutoplayEnabled
		});
	};
};

export const toggleRecastPublishModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_RECAST_PUBLISH_MODAL',
			payload: isVisible
		});
	};
};

export const setIsSidebarMaximised = (isSidebarMaximised: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_SIDEBAR_MAXIMISED',
			payload: isSidebarMaximised
		});
	};
};

export const setScreenSize = (screenSize: ScreenSize): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_SCREEN_SIZE',
			payload: screenSize
		});
	};
};

export const showPurchaseCastsWizard = (data: PurchaseCastsWizard): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_PURCHASE_CASTS_WIZARD',
			payload: data
		});
	};
};

export const showChannelPassesWizard = (data: ChannelPassesWizard): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SHOW_CHANNEL_PASSES_WIZARD',
			payload: data
		});
	};
};

export const setIsMaintenanceMode = (data: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_MAINTENANCE',
			payload: data
		});
	};
};

export const toggleWelcomeToRecastModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_WELCOME_TO_RECAST_MODAL',
			payload: isVisible
		});
	};
};

export const setIsNewSocialSignup = (data: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_IS_NEW_SOCIAL_SIGNUP',
			payload: data
		});
	};
};

export const toggleVideoNotFoundModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_VIDEO_NOT_FOUND_MODAL',
			payload: isVisible
		});
	};
};

export const toggleUnavailableInUserLocationModal = (isVisible: boolean): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_UNAVAILABLE_IN_USER_LOCATION_MODAL',
			payload: isVisible
		});
	};
};

export const setIsEditProfileModalVisible = (isVisible: boolean): SetIsEditProfileModalVisible => ({
	type: 'SET_IS_EDIT_PROFILE_MODAL_VISIBLE',
	payload: isVisible
});

export const setIsSettingsModalVisible = (isVisible: boolean): SetIsSettingsModalVisible => ({
	type: 'SET_IS_SETTINGS_MODAL_VISIBLE',
	payload: isVisible
});

export const setIsDataProfileBonusBannerVisible = (isVisible: boolean): SetIsDataProfileBonusBannerVisible => ({
	type: 'SET_IS_DATA_PROFILE_BONUS_BANNER_VISIBLE',
	payload: isVisible
});

export const toggleReplayProcessingModal = (data: ReplayProcessing): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_REPLAY_PROCESSING_MODAL',
			payload: data
		});
	};
};

export const toggleReplayNotFoundModal = (data: ReplayNotFound): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'TOGGLE_REPLAY_NOT_FOUND_MODAL',
			payload: data
		});
	};
};

export const toggleCommentModal = (data: CommentModal) => ({
	type: 'TOGGLE_COMMENT_MODAL',
	payload: data
});

export const toggleEmbeddedOverlay = (isVisible: boolean) => ({
	type: 'TOGGLE_EMBEDDED_OVERLAY',
	payload: isVisible
});

export const toggleEmbeddedWebviewOverlay = (isVisible: boolean): ToggleEmbeddedWebviewOverlay => ({
	type: 'TOGGLE_EMBEDDED_WEBVIEW_OVERLAY',
	payload: isVisible
});

export const toggleEmbeddedCookiesOverlay = (isVisible: boolean): ToggleEmbeddedCookiesOverlay => ({
	type: 'TOGGLE_EMBEDDED_COOKIES_OVERLAY',
	payload: isVisible
});

export const setEmbeddedMetadata = (data: EmbeddedMetadata) => ({
	type: 'SET_EMBEDDED_METADATA',
	payload: data
});
