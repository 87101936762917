import { AppThunk } from '../../store/store';

export enum PlayerActionTypes {
	ADD_FORCED_PLAYER_UI_VIDEO_ID_LIST = 'ADD_FORCED_PLAYER_UI_VIDEO_ID_LIST',
	REMOVE_FORCED_PLAYER_UI_VIDEO_ID_LIST = 'REMOVE_FORCED_PLAYER_UI_VIDEO_ID_LIST',
	SET_PLAYER_HTTP_ERROR_URL = 'SET_PLAYER_HTTP_ERROR_URL'
}

export interface AddForcedPlayerUiVideoList {
	type: PlayerActionTypes.ADD_FORCED_PLAYER_UI_VIDEO_ID_LIST;
	payload: string | undefined;
}

export interface RemoveForcedPlayerUiVideoList {
	type: PlayerActionTypes.REMOVE_FORCED_PLAYER_UI_VIDEO_ID_LIST;
	payload: string | undefined;
}

export interface SetPlayerHttpErrorUrl {
	type: PlayerActionTypes.SET_PLAYER_HTTP_ERROR_URL;
	payload: string | undefined;
}

export type PlayerActions = AddForcedPlayerUiVideoList | RemoveForcedPlayerUiVideoList | SetPlayerHttpErrorUrl;

export const addForcedPlayerUiVideoId = (videoId?: string): AppThunk => {
	return (dispatch): void => {
		dispatch({ type: 'ADD_FORCED_PLAYER_UI_VIDEO_ID_LIST', payload: videoId });
	};
};

export const removeForcedPlayerUiVideoId = (videoId?: string): AppThunk => {
	return (dispatch): void => {
		dispatch({ type: 'REMOVE_FORCED_PLAYER_UI_VIDEO_ID_LIST', payload: videoId });
	};
};

export const setPlayerHttpErrorUrl = (videoUrl: string): AppThunk => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_PLAYER_HTTP_ERROR_URL',
			payload: videoUrl
		});
	};
};
