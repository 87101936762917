import { isDevelopment, getBuildNumber } from '../src/domain/app/appManager';
import devConfig from './dev/devConfig';
import localConfig from './local/localConfig';
import prodConfig from './prod/prodConfig';

export interface ConfigFromEnvVariables {
	ENV_FULL_NAME: '' | 'development' | 'ephemeral' | 'production' | 'staging' | 'test';
	ENDPOINTS_URL?: string;
	FALLBACK_API_BASE: string;
	FALLBACK_SHARE_BASE: string;
	FALLBACK_PUBLISH_BASE: string;
	FALLBACK_BUSINESS_BASE: string;
	FALLBACK_ACCOUNT_BASE: string;
	FALLBACK_APP_URL: string;
	STRIPE_PUBLISHABLE_KEY: string;
	GOOGLE_ANALYTICS_MEASUREMENT_ID: string;
	GOOGLE_RECAPTCHA_SITE_KEY: string;
	IMAGE_TAG: string;
	SENTRY_DSN: string;
	CHROME_CAST_RECEIVER_ID: string;
	FACEBOOK_APP_ID: string;
	DRM_LICENCE_ENDPOINT_KEY: string;
	AB_TEST_CAROUSEL_ID: string;
	FLAGSMITH_ENVIRONMENT_ID: string;
	FLAGSMITH_POLLING_INTERVAL_MS: number;
	FLAGSMITH_API_URL: string;
	STREAM_CHAT_KEY: string;
	ENABLE_PSEUDO?: boolean;
	USERPILOT_TOKEN: string;
	VAST_URL: string;
	MIXPANEL_TOKEN: string;
	ACCOUNT_APP_ORIGIN: string;
}

export interface ConfigFromReleaseTag {
	REGISTRY: string;
	IMAGE_NAME: string;
	RELEASE_TAG: string;
}

enum Environments {
	DEV = 'DEV',
	PRODUCTION = 'PRODUCTION'
}

const defaultTagConfig: ConfigFromReleaseTag = {
	REGISTRY: '',
	IMAGE_NAME: 'media-web',
	RELEASE_TAG: `release+${getBuildNumber()}`
};

export const tagConfig = (imageTag: string) => {
	const split = imageTag.split(/[/:]+/);

	if (split.length === 3) {
		const [registry, imageName, release] = split;

		return {
			REGISTRY: registry,
			IMAGE_NAME: imageName,
			RELEASE_TAG: release
		};
	}

	return defaultTagConfig;
};

const currentEnvironment: Environments = isDevelopment ? Environments.DEV : Environments.PRODUCTION;

export const getAppConfig = (env: Environments = currentEnvironment) => {
	let configFromEnvVariables = devConfig;

	if (env === Environments.PRODUCTION) {
		configFromEnvVariables = prodConfig;
	}
	if (process.env.USE_LOCAL) {
		configFromEnvVariables = localConfig;
	}

	const configFromReleaseTag = configFromEnvVariables.IMAGE_TAG
		? tagConfig(configFromEnvVariables.IMAGE_TAG)
		: defaultTagConfig;

	return { ...configFromEnvVariables, ...configFromReleaseTag };
};

export default getAppConfig(currentEnvironment);
