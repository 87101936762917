import { AuthActions } from './actions';

export interface AuthState {
	requiresValidation: boolean;
	username: string | null;
	password: string | null;
	fullName: string | null;
	phone: string | null;
	email: string;
	verificationCode?: string | null;
	isValidatedByEmail?: boolean;
	shouldBypassVerification?: boolean | null;
	isLoggedIn: boolean;
}

export const initialAuthState: AuthState = {
	requiresValidation: false,
	username: null,
	password: null,
	phone: null,
	fullName: null,
	email: '',
	verificationCode: null,
	isValidatedByEmail: false,
	shouldBypassVerification: false,
	isLoggedIn: false
};

export default function authReducer(state = initialAuthState, act: AuthActions): AuthState {
	switch (act.type) {
		case 'INIT_AUTH':
			return {
				...state,
				...act.payload
			};

		case 'REGISTER_USER':
			return {
				...state,
				username: act.payload.username,
				password: act.payload.password,
				phone: act.payload.phone,
				fullName: act.payload.fullName,
				email: act.payload.email,
				requiresValidation: true,
				shouldBypassVerification: act.payload.shouldBypassVerification
			};

		case 'CLEAR_REGISTER_USER':
			return {
				...state,
				username: initialAuthState.username,
				password: initialAuthState.password,
				phone: initialAuthState.phone,
				fullName: initialAuthState.fullName,
				email: initialAuthState.email,
				requiresValidation: initialAuthState.requiresValidation,
				shouldBypassVerification: initialAuthState.shouldBypassVerification
			};

		case 'LOGIN_USER':
			return {
				...state,
				...act.payload,
				password: null,
				requiresValidation: false,
				isLoggedIn: true
			};

		case 'LOGOUT_USER':
			return initialAuthState;

		case 'REQUIRE_VALIDATION':
			return {
				...state,
				requiresValidation: act.payload
			};

		case 'SET_IS_VALIDATED_BY_EMAIL':
			return {
				...state,
				isValidatedByEmail: act.payload
			};

		case 'UPDATE_USER_DATA':
			return {
				...state,
				...act.payload
			};

		default:
			return state;
	}
}
