import { DrmProviderName } from '../../domain/player/playerDrmManager';
import { GETWithCurrentLocation, MediaAPIList, User } from './index';
import { GET, POST } from '.';
import { GETVideoAndThumbnails, GETVideoListAndThumbnails, ImageItem } from '../imageApi/imageApi';
import axios from 'axios';
import { Balance } from './me';
import { getPublishersAndNotFollowingUsers } from '../../domain/user/userManager';
import { getValidVideos } from '../../domain/video/videosManager';
import { getLiveChannels } from '../../domain/user/channelManager';
import { getAppConfig } from '../../../config/appConfig';
import { PromotionTypeId } from '../../constants/Common';
import { getRandomNumber } from '../../utils/utils';
import { isSafariBrowser } from '../../domain/browser/browserManager';
import {
	VideoPlay,
	DrmProviderName as SharedDrmProviderName
} from '@content-technology-partners-ltd/shared-data-access';
import { store } from '../../store/store';
import { createPurchaseMetadata } from '../publicApi/utils';

export enum ClipState {
	UPLOADING = 'uploading',
	TRANSCODING = 'transcoding',
	AVAILABLE = 'available'
}

/** Individual playable video file */
export interface Clip {
	/** width of the clip in pixels */
	width: number;
	/** height of the clip in pixels */
	height: number;
	/** closest aspect ratio to the dimensions */
	aspectRatio: 'portrait' | 'square' | 'landscape' | 'widescreen' | 'ultrawide';
	/** length of the clip in seconds */
	duration: number;
	/** a playable file. This URL may only be valid for a set period from
	 * generation, and only for the current IP address. If a replacement
	 * is required the play call should be made again, which will generate
	 * a replacement for free */
	url: string;
	securedUrl: string;
	id: string;
	stateName: ClipState;
}

export enum VideoType {
	UPLOAD = 0,
	BROADCAST = 4,
	AD = 5
}

export enum VideoStatus {
	PUBLISHED = 2,
	PROCESSING = 1,
	UNPUBLISHED = 0
}

interface PaidPlayRules {
	description: string;
	paidplay: {
		regions: string[];
		user_criteria: Record<string, unknown>;
		charge_wallet_id: number;
		earnings: Balance;
	};
}

export interface StatusMessage {
	type: 'ERROR' | 'INFO';
	text: string;
}

export interface ContributorPct {
	userUid: string;
	pct: number;
}

export interface VideoItem {
	uid: string;
	created: string;
	name: string;
	slug: string;
	status: VideoStatus;
	description: string;
	videoTypeId: number;
	extension: string;
	trailers: Clip[];
	clips?: Clip[];
	portrait: number;
	width: number;
	height: number;
	duration: number;
	likeCount: number;
	isLive?: boolean;
	isAdvert?: boolean;
	isFeatured?: boolean;
	priority: number;
	isPremium: boolean;
	/** a playable file. This URL is either for a trailer or for non-premium
	 * content, and should be valid for the lifetime of the video */
	url: string;
	securedUrl: string;
	shareUrl: string;
	thumbnail?: {
		small: string;
		medium: string;
		large: string;
		xlarge?: string;
	};
	paidplayRules?: PaidPlayRules;
	user?: User;
	cost: {
		units: number;
		currency: string;
	};
	paidplayEarn?: Balance;
	recasterPct: number;
	contributorPcts: ContributorPct[];
	startDate: string;
	earnings?: Balance;
	stopDate: string;
	userUid: string;
	playlistUser?: User;
	purchaseCount?: string;
	viewCount?: string;
	recasts?: string;
	tags?: number[];
	locations: string[];
	drmRequested?: string;
	statusMessages?: StatusMessage[];
	unpublishTime?: string;
	promoIds?: PromotionTypeId[];
	promoBrandNames?: string;
	drmId?: string;
	replayOfUid?: string | null;
	isCamcast?: boolean;
	thumbnailImage?: ImageItem;
}

export interface CdnDrmInfo {
	provider: DrmProviderName | SharedDrmProviderName;
	certificate: string;
	licenseUrl: string;
}

export interface CdnInfo {
	endpoints: {
		hls: string;
		dash: string;
	};
	fromStart?: {
		hls: string;
		dash?: string;
	};
	security: string;
	drm?: CdnDrmInfo;
}

export interface DrmInfo {
	enabled: boolean;
	provider: DrmProviderName | SharedDrmProviderName;
	buydrm_certificate: string;
	buydrm_proxy: string;
}

export const drmInitialValues: DrmInfo = {
	enabled: false,
	provider: DrmProviderName.None,
	buydrm_certificate: '',
	buydrm_proxy: ''
};

export interface VideoPaidInfo {
	uid: string;
	/** Array of clips containing the full video, all with currently-valid URLs.
	 * This should allow multiple shots or alternate aspect ratios in the future */
	paidClips: Clip[];
	/** a playable file. This URL may only be valid for a set period from
	 * generation, and only for the current IP address. If a replacement
	 * is required the play call should be made again, which will generate
	 * a replacement for free */
	fullUrl: string;
	drm: DrmInfo;
	cdns?: CdnInfo[] | null;
}

export interface PlayResponse {
	play: {
		video: VideoPaidInfo;
		/** Amount paid */
		price: Balance;
		created: string;
		token: string;
	};
	/** User's balance following this transaction */
	balance: Balance | string;
}

export interface WatchedVideo {
	videoUid: string;
	date: string;
}

export const DRM_LICENSE_BASE_URL = 'https://lgskueksn1.execute-api.eu-west-1.amazonaws.com/all/drm-token';

interface CdnWithDrm {
	url: string;
	fromStartUrl?: string;
	drm?: CdnDrmInfo;
}

export const getRandomCdn = (response: PlayResponse | VideoPlay): CdnWithDrm | null => {
	const cdns = response.play.video.cdns;
	if (!cdns) return null;

	const index = getRandomNumber(cdns.length);
	const cdn = cdns[index];
	const drm = cdn.drm;

	const endpoints = cdn.endpoints;
	const fromStart = cdn.fromStart;
	const hls = endpoints.hls;

	if (!drm && fromStart !== undefined) {
		return { url: hls, fromStartUrl: fromStart.hls };
	}

	if (!drm) return { url: hls };

	if (!isSafariBrowser() && fromStart !== undefined && fromStart.dash !== undefined) {
		return { url: endpoints.dash, drm, fromStartUrl: fromStart.dash };
	}

	if (!isSafariBrowser()) {
		return { url: endpoints.dash, drm };
	}

	if (fromStart !== undefined) {
		return { url: hls, drm, fromStartUrl: fromStart.hls };
	}

	return { url: hls, drm };
};

export const cdnDrmInfoToDrmInfo = (drm?: CdnDrmInfo): DrmInfo =>
	drm
		? {
				enabled: true,
				buydrm_certificate: drm.certificate,
				buydrm_proxy: drm.licenseUrl,
				provider: drm.provider
		  }
		: drmInitialValues;

export const getDiscoverPublishers = async () => {
	const res = await GETWithCurrentLocation<User[]>('assets/explore/users');
	return getPublishersAndNotFollowingUsers(res.data.data);
};

export const pay = async (videoId: string, expectedPrice: number, recasterUid?: string) => {
	const embeddedMetadata = store.getState().app.embedded;

	const res = await POST<PlayResponse>(`video/play/${videoId}`, {
		castPrice: expectedPrice,
		recasterUid: recasterUid || null,
		metadata: createPurchaseMetadata(embeddedMetadata)
	});
	return res.data.data;
};

export const playVideo = async (videoId: string): Promise<PlayResponse> => {
	const EXPECTED_PRICE_WHEN_IS_PURCHASED = 0;

	const res = await POST<PlayResponse>(`video/play/${videoId}`, {
		castPrice: EXPECTED_PRICE_WHEN_IS_PURCHASED
	});
	return res.data.data;
};

export const getVideoInfo = async (videoId: string, shouldExpandUser?: boolean) => {
	const videoPromise = GET<VideoItem>(`video/${videoId}${shouldExpandUser ? '?expand=user' : ''}`);
	return await GETVideoAndThumbnails(videoPromise, videoId);
};

export const searchUser = async (value: string): Promise<User[]> => {
	interface Response {
		items: User[];
	}

	const response = await GET<Response>(`search/users/${value}`);
	const users = response.data.data.items;
	return getLiveChannels(users) as User[];
};

export const getVideosInfo = async (videosUids: string[]): Promise<VideoItem[]> => {
	const videoPromise = GET<MediaAPIList<VideoItem>>(`videos/info/${videosUids.join(',')}?expand=user`);
	const videoListWithThumbnails = await GETVideoListAndThumbnails(videoPromise, videosUids);

	return getValidVideos(videoListWithThumbnails);
};

export const getDrmToken = async (uid: string, tokenType: string, userUid: string) => {
	const config = { headers: { 'X-API-KEY': getAppConfig().DRM_LICENCE_ENDPOINT_KEY } };
	const res = await axios.get(`${DRM_LICENSE_BASE_URL}?uid=${uid}&drm=${tokenType}&userId=${userUid}`, config);
	return res.data.token;
};
