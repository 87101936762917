import { isMobile } from 'react-device-detect';
import { IS_SIDEBAR_COLLAPSED_KEY } from '../../constants/Common';
import { localStorageEntry } from '@content-technology-partners-ltd/shared-util';

const isSidebarCollapsedEntry = localStorageEntry(IS_SIDEBAR_COLLAPSED_KEY, false);

export const getIsSidebarCollapsedByDefault = isSidebarCollapsedEntry.get;

export const saveIsSidebarCollapsedByDefault = isSidebarCollapsedEntry.set;

export const getIsSidebarMaximisedInitially = () => {
	return !isMobile && !getIsSidebarCollapsedByDefault();
};
