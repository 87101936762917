export enum NotificationType {
	'LIVE_EVENT' = 'LIVE_EVENT',
	'NEW_VIDEO' = 'NEW_VIDEO',
	'NEW_VIDEOS' = 'NEW_VIDEOS',
	'NEW_FOLLOWER' = 'NEW_FOLLOWER',
	'NEW_REFERRAL' = 'NEW_REFERRAL'
}

export enum NotificationStatus {
	'NEW' = 'NEW',
	'READ' = 'READ',
	'NOT_FETCHED' = 'NOT_FETCHED'
}

interface Notification {
	type: NotificationType;
	notificationId: string;
	status: NotificationStatus;
	date: string;
	uid?: string;
	[key: string]: any;
}

export enum NotificationAttributes {
	LIVE = 'livestreamId',
	NEW_VIDEO = 'videoId',
	NEW_FOLLOWER = 'followerUid',
	NEW_REFERRAL = 'refereeUid'
}

interface LiveNotification extends Notification {
	[NotificationAttributes.LIVE]: string;
}

interface NewVideoNotification extends Notification {
	[NotificationAttributes.NEW_VIDEO]: string;
}

export interface NewFollowerNotification extends Notification {
	[NotificationAttributes.NEW_FOLLOWER]: string;
}

export interface NewReferralNotification extends Notification {
	[NotificationAttributes.NEW_REFERRAL]: string;
}

export type SingleNotification =
	| NewFollowerNotification
	| NewVideoNotification
	| LiveNotification
	| NewReferralNotification;

export enum CommunicationChannel {
	PUSH = 'PUSH_NOTIFICATIONS',
	EMAIL = 'EMAIL',
	WEBSOCKET = 'WEBSOCKET'
}

export interface CommunicationPreference {
	type: NotificationType;
	enabledChannels: CommunicationChannel[];
}

export type CommunicationPreferences = CommunicationPreference[];

export interface NotificationsPreferences {
	enabledChannels?: CommunicationChannel[];
	communicationPreferences?: CommunicationPreferences;
}

export interface NewVideoNotificationSubscription {
	publisherUid: string;
	exists: boolean;
}
