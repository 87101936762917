import i18next from 'i18next';
import * as serviceWorker from '../../serviceWorker';
import { forceReloadPage } from '../browser/browserManager';

enum LanguageDirections {
	LTR = 'ltr',
	RTL = 'rtl'
}

// NOTE: These describe the app build env, not the actual environment they end up on
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';

const VERSION_LENGTH = 5;

export const getBuildNumber = (): string => __webpack_hash__.substr(-VERSION_LENGTH);

export const getAppVersion = (): string => (process.env.NODE_ENV === 'production' ? `prod@${getBuildNumber()}` : 'dev');

export const markPageAsDesktopReady = () => {
	document.body.className = 'desktopReady';
};

export const unmarkPageAsDesktopReady = () => {
	document.body.className = '';
};

export const restartApp = (timeout = 0) => {
	serviceWorker.unregister();
	timeout ? setTimeout(forceReloadPage, timeout) : forceReloadPage();
};

export const isRtlLanguage = () => {
	return i18next.dir() === LanguageDirections.RTL;
};
