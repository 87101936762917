import dayjs from 'dayjs';
import { captureException } from '@sentry/react';
import { getAuthAccessToken } from '../../domain/auth/authTokenManager';
import { SocialSignupType } from './auth';
import { FIVE_MINUTES_IN_SECONDS, REFRESH_TOKEN_LIFETIME_IN_SECONDS } from '../../constants/Common';

export enum TypeOfRole {
	User = 'User',
	ChannelManager = 'ChannelManager',
	PartnerManager = 'PartnerManager'
}

enum PermissionType {
	Admin = 'ADMIN'
}

export interface Role {
	name: TypeOfRole;
	resources: { id: string; permissions: PermissionType[] }[];
}

export interface TokenPayload {
	exp: number;
	iat: number;
	isAdmin: boolean;
	isContributor: boolean;
	isPartnerStaff: boolean;
	isPublisher: boolean;
	roles: Role[];
	tokenId: number;
	uid: string;
	username: string;
	isPasswordSet: boolean;
	socialConnections: SocialSignupType[];
}

export const decodeJwtToken = (token?: string): TokenPayload | null => {
	if (token) {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (error) {
			captureException(error);
			return null;
		}
	}
	return null;
};

// checks for expiration, but within a tolerance range due to drift
export const isTokenPotentiallyExpired = (token: string): boolean => {
	const decodedToken = decodeJwtToken(token);

	if (!decodedToken) return true;

	const parsedTokenExpirationDate = dayjs
		.unix(decodedToken.exp)
		.utc()
		.subtract(FIVE_MINUTES_IN_SECONDS, 'second');

	const isExpirationInTheFuture = dayjs.utc(parsedTokenExpirationDate).isAfter(dayjs.utc());

	return !isExpirationInTheFuture;
};

export const isExpiredRefreshToken = (token: string): boolean => {
	const decodedToken = decodeJwtToken(token);
	if (!decodedToken) return true;

	const parsedTokenCreatedDate = dayjs
		.unix(decodedToken.iat)
		.utc()
		.format();

	const isTokenWithinLifetime = dayjs
		.utc(parsedTokenCreatedDate)
		.isAfter(dayjs.utc().subtract(REFRESH_TOKEN_LIFETIME_IN_SECONDS, 'second'));

	return !isTokenWithinLifetime;
};

export const getTokenPayload = (): TokenPayload | null => {
	return decodeJwtToken(getAuthAccessToken());
};
