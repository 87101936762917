import dayjs from 'dayjs';
import { DateFormat, LAST_READ_NOTIFICATIONS_DATE_KEY, READ_NOTIFICATIONS_KEY } from '../../constants/Common';
import { getLoggedUserId } from '../auth/authManager';

export const getReadNotificationsFromStorage = (): string[] => {
	return JSON.parse(localStorage.getItem(READ_NOTIFICATIONS_KEY) || '[]') as string[];
};

export const clearReadNotificationsFromStorage = () => {
	return localStorage.removeItem(READ_NOTIFICATIONS_KEY);
};

export const saveReadNotificationsInStorage = (notificationsId: string[]) => {
	const newReadNotifications = [...getReadNotificationsFromStorage(), ...notificationsId];
	const newReadNotificationsWithoutDuplicate = [...new Set(newReadNotifications)];

	localStorage.setItem(READ_NOTIFICATIONS_KEY, JSON.stringify(newReadNotificationsWithoutDuplicate));
};

const getLastReadNotificationDateKeyForUser = (): string => {
	return LAST_READ_NOTIFICATIONS_DATE_KEY + '-' + getLoggedUserId();
};

export const getLastReadNotificationDate = (): string => {
	return localStorage.getItem(getLastReadNotificationDateKeyForUser()) ?? '';
};

export const saveLastReadNotificationDate = () => {
	const today = dayjs().format(DateFormat.API);
	localStorage.setItem(getLastReadNotificationDateKeyForUser(), JSON.stringify(today));
};
