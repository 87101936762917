import axios, { AxiosError, AxiosResponse } from 'axios';
import { getBaseApiUrl } from '../api/api';
import { store } from '../../store/store';
import { setIsMaintenanceMode } from '../../store/app/actions';
import { HTTP_STATUS_CODES } from '../../constants/Common';

const statusClient = axios.create();

const getStatusUrl = (): string => `${getBaseApiUrl()}/status`;

export const getAppStatus = async (): Promise<AxiosResponse> => {
	return await statusClient.get(getStatusUrl());
};

export const handleAppStatusError = (error: AxiosError) => {
	error.response?.status === HTTP_STATUS_CODES.TEMPORARY_UNAVAILABLE
		? store.dispatch<any>(setIsMaintenanceMode(true))
		: store.dispatch<any>(setIsMaintenanceMode(false));
};

export const handleAppStatusSuccess = () => store.dispatch<any>(setIsMaintenanceMode(false));
