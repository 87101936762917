import { AppActions } from './actions';
import { ALL_FILTER_ID } from '../../constants/Common';
import { User, Country } from '../../utils/mediaApi';
import { Categories } from '../../domain/tracking/googleAnalyticsManager';
import { Channel } from '../../utils/mediaApi/me';
import RoutePaths from '../../navigation/routePaths';
import { getIsSidebarMaximisedInitially } from '../../domain/user/sidebarManager';
import { FlagCommentErrorTypes } from '../../utils/commentsApi/commentsApi';

import { VideoListingTypes } from '@content-technology-partners-ltd/shared-data-access';
import type { ChannelListing, VideoListing } from '@content-technology-partners-ltd/shared-data-access';

export enum VideoModalType {
	NONE = 'none',
	ANY = 'any',

	// bottom modals
	SHARE_AS_CHANNEL = 'shareAsChannel',
	SHARE_AND_EARN = 'shareAndEarn',
	SHARE = 'share',
	REPORT = 'report',
	STREAM_REPORT = 'streamReport',

	// report modals
	STREAM_QUALITY = 'streamQuality',
	REPORT_MODAL = 'reportModal',
	REPORT_SUCCESS = 'reportSuccess',
	REPORT_ERROR = 'reportError',
	REPORT_ALREADY_FLAGGED = 'reportAlreadyFlagged'
}

export interface SelectionForPlay {
	videoId: string;
	videoIndex: number;
}

export interface VideoModalConfig {
	type: VideoModalType;
	video?: VideoListing;
	position?: { top: number; bottom: number; left: number; right: number };
	channel?: Channel;
}

export interface SupportModal {
	isVisible: boolean;
	message: string;
}

export interface SignUpModal {
	isVisible: boolean;
	onClose?: () => void;
	channelId?: string;
	video?: VideoListing;
	sharedVideo?: VideoListing;
	gaCategory?: Categories;
	header?: string;
	originRoute?: RoutePaths;
}

export interface ChannelPassModal {
	isVisible: boolean;
	balance?: number;
	video?: VideoListing;
	sharingChannelId?: string;
}

export interface SignUpFollowUpData {
	video?: VideoListing;
	channelId?: string;
}

export interface ChannelFollowUpModalData {
	userId?: string;
}

export interface OneTrustCookieAgreement {
	essential: boolean;
	analytics: boolean;
	functionality: boolean;
	advertising: boolean;
	socialMedia: boolean;
}

export interface ScreenSize {
	isWidescreen: boolean;
	windowWidth: number;
}

export interface PurchaseCastsWizard {
	isVisible: boolean;
	videoId?: string;
	channelId?: string;
	videoType?: VideoListingTypes;
	cost?: number;
}

export interface ChannelPassesWizard {
	isVisible: boolean;
	price: number;
	id: string;
	duration: number;
	channelName: string;
	channelAvatar: string;
	redirectToVideo?: string;
}

export interface ReplayProcessing {
	isVisible: boolean;
	channelName?: string;
}

export interface ReplayNotFound {
	isVisible: boolean;
	channelName?: string;
}

interface CommentDeleted {
	type: 'comment-delete';
	isAdmin: boolean;
	username?: string;
	error?: boolean;
}

interface CommentFlag {
	type: 'comment-flag';
	error?: FlagCommentErrorTypes;
}

interface CommentBan {
	type: 'comment-ban';
	username: string;
	error?: boolean;
}

interface CommentUserBanned {
	type: 'comment-user-banned';
}

type CommentModals = CommentDeleted | CommentFlag | CommentBan | CommentUserBanned | null;

export interface CommentModal {
	isVisible: boolean;
	modal: CommentModals;
}

export interface EmbeddedMetadata {
	widgetId?: string;
	channelId?: string;
}

export interface EmbeddedOverlay {
	isVisible: boolean;
	onContinueClicked: () => void;
}

export interface AppState {
	name: string;
	topModalMessage: string;
	confirmModalMessage: string;
	isVisibleInviteModal: boolean;
	isVisibleAuthErrorModal: boolean;
	isVisibleNotSupportedRegionModal: boolean;
	channelNotificationsModal: ChannelFollowUpModalData;
	isVisibleChannelPicker: boolean;
	isVisibleNotLoggedModal: boolean;
	isVisibleRecastPublishModal: boolean;
	isVisibleWelcomeToRecastModal: boolean;
	isSearching: boolean;
	searchValue: string;
	exploreFilterId: number;
	isHomePageVideosMuted: boolean;
	isOffline: boolean;
	supportedCountries: Country[];
	hasWatchedLatestPreview: boolean;
	isVisibleFollowNotificationModal: boolean;
	followNotificationModalUser?: User | ChannelListing;
	supportModal: SupportModal;
	loginEmailValue: string;
	videoSelectedForPlay?: SelectionForPlay;
	isPurchasingVideo: boolean;
	sharedVideo?: VideoListing;
	isRegistrationUnavailableModalVisible: boolean;
	signUpModal: SignUpModal;
	suggestChannelPassModal: ChannelPassModal;
	signUpFollowUp: SignUpFollowUpData;
	isLoggedOutPreviewComplete: boolean;
	cookieAgreement?: OneTrustCookieAgreement;
	isAutoplayEnabled: boolean;
	isSidebarMaximised: boolean;
	screenSize: ScreenSize;
	purchaseCastsWizard: PurchaseCastsWizard;
	channelPassesWizard: ChannelPassesWizard;
	isMaintenanceMode: boolean;
	isNewSocialSignup: boolean;
	isVideoNotFoundModalVisible: boolean;
	isUnavailableInUserLocationModalVisible: boolean;
	isEditProfileModalVisible: boolean;
	isSettingsModalVisible: boolean;
	isDataProfileBonusBannerVisible: boolean;
	isReplayProcessingModalVisible: ReplayProcessing;
	isReplayNotFoundModalVisible: ReplayNotFound;
	isCommentModalVisible: CommentModal;
	isEmbeddedOverlayVisible: boolean;
	isEmbeddedWebviewOverlayVisible: boolean;
	isEmbeddedCookiesOverlayVisible: boolean;
	embedded: EmbeddedMetadata;
}

export const initialState: AppState = {
	name: 'RECAST',
	topModalMessage: '',
	confirmModalMessage: '',
	isVisibleInviteModal: false,
	isVisibleAuthErrorModal: false,
	isVisibleNotSupportedRegionModal: false,
	isVisibleChannelPicker: false,
	isVisibleNotLoggedModal: false,
	isVisibleRecastPublishModal: false,
	isVisibleWelcomeToRecastModal: false,
	channelNotificationsModal: {
		userId: undefined
	},
	isSearching: false,
	searchValue: '',
	isOffline: false,
	isHomePageVideosMuted: true,
	exploreFilterId: ALL_FILTER_ID,
	supportedCountries: [],
	hasWatchedLatestPreview: false,
	isVisibleFollowNotificationModal: false,
	followNotificationModalUser: undefined,
	supportModal: {
		isVisible: false,
		message: ''
	},
	loginEmailValue: '',
	isPurchasingVideo: false,
	sharedVideo: undefined,
	isRegistrationUnavailableModalVisible: false,
	signUpModal: {
		isVisible: false
	},
	signUpFollowUp: {
		video: undefined,
		channelId: undefined
	},
	isLoggedOutPreviewComplete: false,
	isAutoplayEnabled: true,
	isSidebarMaximised: getIsSidebarMaximisedInitially(),
	screenSize: {
		isWidescreen: false,
		windowWidth: 0
	},
	purchaseCastsWizard: {
		isVisible: false,
		videoId: undefined,
		videoType: undefined,
		cost: undefined
	},
	channelPassesWizard: {
		isVisible: false,
		id: '',
		duration: 0,
		price: 0,
		channelName: '',
		channelAvatar: '',
		redirectToVideo: undefined
	},
	suggestChannelPassModal: {
		isVisible: false
	},
	isMaintenanceMode: false,
	isNewSocialSignup: false,
	isVideoNotFoundModalVisible: false,
	isUnavailableInUserLocationModalVisible: false,
	isEditProfileModalVisible: false,
	isSettingsModalVisible: false,
	isDataProfileBonusBannerVisible: false,
	isReplayProcessingModalVisible: {
		isVisible: false,
		channelName: undefined
	},
	isReplayNotFoundModalVisible: {
		isVisible: false,
		channelName: undefined
	},
	isCommentModalVisible: {
		isVisible: false,
		modal: null
	},
	embedded: {
		widgetId: undefined,
		channelId: undefined
	},
	isEmbeddedOverlayVisible: false,
	isEmbeddedWebviewOverlayVisible: false,
	isEmbeddedCookiesOverlayVisible: false
};

export default function appReducer(state = initialState, action: AppActions): AppState {
	switch (action.type) {
		case 'SHOW_TOP_MODAL':
			return {
				...state,
				topModalMessage: action.payload
			};

		case 'CLEAR_TOP_MODAL':
			return {
				...state,
				topModalMessage: ''
			};

		case 'TOGGLE_INVITE_MODAL_VISIBILITY_ACTION':
			return {
				...state,
				isVisibleInviteModal: action.payload
			};

		case 'TOGGLE_AUTH_ERROR_MODAL':
			return {
				...state,
				isVisibleAuthErrorModal: action.payload
			};

		case 'SET_SEARCH_VALUE':
			return {
				...state,
				searchValue: action.payload
			};

		case 'TOGGLE_SEARCHING':
			return {
				...state,
				isSearching: action.payload
			};

		case 'SET_IS_OFFLINE':
			return {
				...state,
				isOffline: action.payload
			};

		case 'SET_EXPLORE_FILTER_ID':
			return {
				...state,
				exploreFilterId: action.payload
			};

		case 'TOGGLE_HOMEPAGE_VIDEOS_MUTED':
			return {
				...state,
				isHomePageVideosMuted: action.payload
			};

		case 'SHOW_NOT_SUPPORTED_REGION_MODAL':
			return {
				...state,
				isVisibleNotSupportedRegionModal: true
			};

		case 'HIDE_NOT_SUPPORTED_REGION_MODAL':
			return {
				...state,
				isVisibleNotSupportedRegionModal: false
			};

		case 'SET_CHANNEL_NOTIFICATIONS_MODAL':
			return {
				...state,
				channelNotificationsModal: {
					userId: action.payload
				}
			};

		case 'CLEAR_CHANNEL_NOTIFICATIONS_MODAL':
			return {
				...state,
				channelNotificationsModal: {
					userId: undefined
				}
			};

		case 'GET_SUPPORTED_COUNTRIES':
			return {
				...state,
				supportedCountries: action.payload
			};

		case 'SET_HAS_WATCHED_LATEST_PREVIEW':
			return {
				...state,
				hasWatchedLatestPreview: action.payload
			};
		case 'SHOW_FOLLOW_NOTIFICATION_MODAL_WITH_USER':
			return {
				...state,
				isVisibleFollowNotificationModal: true,
				followNotificationModalUser: action.payload
			};
		case 'HIDE_FOLLOW_NOTIFICATION_MODAL':
			return {
				...state,
				isVisibleFollowNotificationModal: false,
				followNotificationModalUser: undefined
			};
		case 'SHOW_SUPPORT_MODAL':
			return {
				...state,
				supportModal: { isVisible: true, message: action.payload }
			};

		case 'HIDE_SUPPORT_MODAL':
			return {
				...state,
				supportModal: { isVisible: false, message: '' }
			};

		case 'SET_LOGIN_EMAIL_VALUE':
			return {
				...state,
				loginEmailValue: action.payload
			};

		case 'SELECT_VIDEO_FOR_PLAY':
			return {
				...state,
				videoSelectedForPlay: action.payload
			};

		case 'CLEAR_VIDEO_SELECTION_FOR_PLAY':
			return {
				...state,
				videoSelectedForPlay: undefined
			};

		case 'TOGGLE_CHANNEL_PICKER_VISIBILITY':
			return {
				...state,
				isVisibleChannelPicker: action.payload
			};

		case 'SET_SIGN_UP_MODAL':
			return {
				...state,
				signUpModal: action.payload
			};

		case 'SET_SUGGEST_CHANNEL_PASS_MODAL':
			return {
				...state,
				suggestChannelPassModal: action.payload
			};
		case 'SET_SIGN_UP_FOLLOW_UP':
			return {
				...state,
				signUpFollowUp: action.payload
			};

		case 'CLEAR_SIGN_UP_FOLLOW_UP':
			return {
				...state,
				signUpFollowUp: {
					video: undefined,
					channelId: undefined
				}
			};

		case 'SET_IS_PURCHASING_VIDEO':
			return {
				...state,
				isPurchasingVideo: action.payload
			};

		case 'SET_SHARED_VIDEO':
			return {
				...state,
				sharedVideo: action.payload
			};

		case 'CLEAR_SHARED_VIDEO':
			return {
				...state,
				sharedVideo: initialState.sharedVideo
			};

		case 'TOGGLE_REGISTRATION_UNAVAILABLE_MODAL':
			return {
				...state,
				isRegistrationUnavailableModalVisible: action.payload
			};

		case 'SET_IS_LOGGED_OUT_PREVIEW_COMPLETE':
			return {
				...state,
				isLoggedOutPreviewComplete: action.payload
			};

		case 'UPDATE_COOKIE_AGREEMENT':
			return {
				...state,
				cookieAgreement: action.payload
			};

		case 'SET_IS_AUTOPLAY_ENABLED':
			return {
				...state,
				isAutoplayEnabled: action.payload
			};

		case 'TOGGLE_RECAST_PUBLISH_MODAL':
			return {
				...state,
				isVisibleRecastPublishModal: action.payload
			};

		case 'SET_IS_SIDEBAR_MAXIMISED':
			return {
				...state,
				isSidebarMaximised: action.payload
			};

		case 'SET_SCREEN_SIZE':
			return {
				...state,
				screenSize: action.payload
			};

		case 'SHOW_PURCHASE_CASTS_WIZARD':
			return {
				...state,
				purchaseCastsWizard: action.payload
			};
		case 'SHOW_CHANNEL_PASSES_WIZARD':
			return {
				...state,
				channelPassesWizard: action.payload
			};
		case 'SET_IS_MAINTENANCE':
			return {
				...state,
				isMaintenanceMode: action.payload
			};

		case 'TOGGLE_WELCOME_TO_RECAST_MODAL':
			return {
				...state,
				isVisibleWelcomeToRecastModal: action.payload
			};

		case 'SET_IS_NEW_SOCIAL_SIGNUP':
			return {
				...state,
				isNewSocialSignup: action.payload
			};

		case 'TOGGLE_VIDEO_NOT_FOUND_MODAL':
			return {
				...state,
				isVideoNotFoundModalVisible: action.payload
			};

		case 'TOGGLE_UNAVAILABLE_IN_USER_LOCATION_MODAL':
			return {
				...state,
				isUnavailableInUserLocationModalVisible: action.payload
			};

		case 'SET_IS_EDIT_PROFILE_MODAL_VISIBLE':
			return {
				...state,
				isEditProfileModalVisible: action.payload
			};

		case 'SET_IS_SETTINGS_MODAL_VISIBLE':
			return {
				...state,
				isSettingsModalVisible: action.payload
			};

		case 'SET_IS_DATA_PROFILE_BONUS_BANNER_VISIBLE':
			return {
				...state,
				isDataProfileBonusBannerVisible: action.payload
			};

		case 'TOGGLE_REPLAY_PROCESSING_MODAL':
			return {
				...state,
				isReplayProcessingModalVisible: action.payload
			};

		case 'TOGGLE_REPLAY_NOT_FOUND_MODAL':
			return {
				...state,
				isReplayNotFoundModalVisible: action.payload
			};

		case 'TOGGLE_COMMENT_MODAL':
			return {
				...state,
				isCommentModalVisible: action.payload
			};

		case 'SET_EMBEDDED_METADATA':
			return {
				...state,
				embedded: action.payload
			};

		case 'TOGGLE_EMBEDDED_OVERLAY':
			return {
				...state,
				isEmbeddedOverlayVisible: action.payload
			};
		case 'pop-up/already-opened':
			return {
				...state,
				isEmbeddedOverlayVisible: true
			};

		case 'TOGGLE_EMBEDDED_WEBVIEW_OVERLAY':
			return {
				...state,
				isEmbeddedWebviewOverlayVisible: action.payload
			};

		case 'TOGGLE_EMBEDDED_COOKIES_OVERLAY':
			return {
				...state,
				isEmbeddedCookiesOverlayVisible: action.payload
			};

		default:
			return state;
	}
}
