import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getBaseApiUrl } from '../api/api';
import { getAuthAccessToken } from '../../domain/auth/authTokenManager';

export interface Facet {
	href: string;
	count: number;
	results: {
		href: string;
	};
}

export interface Facets {
	categories?: Facet[];
}

export interface PublicApiItem {
	href: string;
}

export interface PublicApiPaging {
	count: number;
	next?: {
		href: string;
	};
}

export interface PublicApiResponseData<TItem = any, TItems = any, TExpanded = any> {
	item?: TItem;
	items?: TItems[];
	expanded: TExpanded[];
	href: string;
	paging?: PublicApiPaging;
	facets?: Facets[];
}

interface PublicApiResponseMessage {
	type: string;
	message: string;
	severity: string;
}

export interface PublicApiResponse extends AxiosResponse {
	data: PublicApiResponseData;
	messages: PublicApiResponseMessage[];
}

export enum PublicApiError {
	NotFound = 'NotFoundError',
	UnavailableInUserLocation = 'UnavailableInUserLocation'
}

const axiosConfig: AxiosRequestConfig = {
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' }
};

export const client = axios.create(axiosConfig);

const AUTHORIZED_URLS = ['me/followedCategories', 'me/purchases'];

const isAuthorizedUrl = (url?: string) =>
	url ? AUTHORIZED_URLS.some(authorizedUrl => url.includes(authorizedUrl)) : false;

const addToken = (config: InternalAxiosRequestConfig) => {
	const token = getAuthAccessToken();
	if (token && isAuthorizedUrl(config.url)) {
		config.headers.set('Authorization', `Bearer ${token}`);
	}
	return config;
};

client.interceptors.request.use(addToken, Promise.reject);

export const getPublicApiUrl = (suffix: string): string => `${getBaseApiUrl()}/api/public/${suffix}`;

export const extractPublicApiResponseMessagesFromError = (error: any): PublicApiResponseMessage[] | undefined => {
	if (error?.response) {
		const { response }: AxiosError<PublicApiResponse> = error;
		return response.data.messages;
	}
	return undefined;
};

export const GET = <T = PublicApiResponse>(url: string, params?: URLSearchParams, configData?: any) =>
	client.get<T>(getPublicApiUrl(url), { params, data: configData });

export const GETNextPage = <T = PublicApiResponse>(nextHref: string) => client.get<T>(`${getBaseApiUrl()}${nextHref}`);

export const POST = <T = PublicApiResponse>(url: string, data?: any, params?: any, configData?: any) =>
	client.post<T>(getPublicApiUrl(url), data, { params, data: configData });

export const DELETE = <T = PublicApiResponse>(url: string, params?: any, configData?: any) =>
	client.delete<T>(getPublicApiUrl(url), { params, data: configData });

const publicApiErrorValues = Object.values(PublicApiError);
export const isPublicApiError = (error: unknown): boolean =>
	!!extractPublicApiResponseMessagesFromError(error)?.some(({ type }) =>
		publicApiErrorValues.includes(type as PublicApiError)
	);

export const isNotFoundError = (error: unknown): boolean =>
	!!extractPublicApiResponseMessagesFromError(error)?.some(({ type }) => type === PublicApiError.NotFound);

export const isUnavailableInUserLocationError = (error: unknown): boolean =>
	!!extractPublicApiResponseMessagesFromError(error)?.some(
		({ type }) => type === PublicApiError.UnavailableInUserLocation
	);

export const prepareDummyPaging = (count: number): PublicApiPaging => ({ count, next: { href: '' } });
