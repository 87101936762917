import { AppThunk } from '../../store/store';
import { fetchPaymentConfig, PaymentConfig } from '../../utils/paymentApi/payment';

export enum PaymentActionTypes {
	GET_PAYMENT_CONFIG = 'GET_PAYMENT_CONFIG'
}

interface PaymentAction {
	type: PaymentActionTypes;
}

export interface GetPaymentConfigAction extends PaymentAction {
	type: PaymentActionTypes.GET_PAYMENT_CONFIG;
	payload: PaymentConfig;
}

export type PaymentActions = GetPaymentConfigAction;

export const getPaymentConfig = (location: string): AppThunk => {
	return async dispatch => {
		dispatch({
			type: PaymentActionTypes.GET_PAYMENT_CONFIG,
			payload: await fetchPaymentConfig(location)
		});
	};
};
