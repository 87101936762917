import dayjs, { Dayjs } from 'dayjs';
import { DateFormat, DISPLAY_VIDEO_DURATION_ADJUSTMENT } from '../constants/Common';

export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_DAY_IN_SECONDS = 86400;
export const ONE_SECONDS_IN_MS = 1000;
export const DAYS_IN_WEEK_COUNT = 7;

export interface DateOffset {
	value: number;
	unit: dayjs.ManipulateType;
}

export enum DateSortOrder {
	NewestFirst,
	OldestFirst
}

const durationIsOverHours = (durationInSeconds: number, hours = 1) =>
	dayjs.duration(durationInSeconds, 'seconds').asHours() >= hours;

const selectReadableFormat = (durationInSeconds: number, shorter?: boolean, withSecondsOverAnHour?: boolean) => {
	if (durationIsOverHours(durationInSeconds, 24)) return DateFormat.READABLE_DURATION_HOURS_UI;
	return durationIsOverHours(durationInSeconds)
		? shorter
			? withSecondsOverAnHour
				? DateFormat.READABLE_DURATION_HOURS_WITH_SECONDS_SHORTER_UI
				: DateFormat.READABLE_DURATION_HOURS_SHORTER_UI
			: DateFormat.READABLE_DURATION_HOURS_UI
		: shorter
		? DateFormat.READABLE_DURATION_MINUTES_SHORTER_UI
		: DateFormat.READABLE_DURATION_MINUTES_UI;
};

export const getDuration = (durationInSeconds: number, withAdjustment = true) => {
	return durationInSeconds
		? withAdjustment
			? durationInSeconds - DISPLAY_VIDEO_DURATION_ADJUSTMENT
			: durationInSeconds
		: 0;
};

//example for 5405 seconds: default - 1h 30m, withoutRounding - 01:30:05
export const getReadableDurationFormatted = (durationInSeconds: number, withoutRounding?: boolean) => {
	const duration = dayjs.duration(getDuration(durationInSeconds, false), 'seconds');
	const days = durationIsOverHours(durationInSeconds, 24) ? `${Math.trunc(duration.asDays())}d ` : '';

	return `${days}${dayjs
		.utc(duration.asMilliseconds())
		.format(selectReadableFormat(durationInSeconds, withoutRounding, withoutRounding))}`;
};
export const getDurationFormatted = (
	durationInSeconds: number,
	withAdjustment = false,
	forceDisplayingHours = false
): string =>
	dayjs
		.utc(dayjs.duration(getDuration(durationInSeconds, withAdjustment), 'seconds').asMilliseconds())
		.format(
			forceDisplayingHours || durationInSeconds >= ONE_HOUR_IN_SECONDS
				? DateFormat.DURATION_UI
				: DateFormat.SHORT_DURATION_UI
		);

export const getTimeFromNow = (date: Date | Dayjs | string): string => dayjs.utc(date).local().fromNow();

export const getDateAndTimeFormatted = (
	utcDateTime: Date | Dayjs | string,
	format: string = DateFormat.DATE_AND_TIME_UI
): string => dayjs.utc(utcDateTime).local().format(format);

export const getCurrentLocalDateAndTime = (format: string = DateFormat.API, offset?: DateOffset): string => {
	let date = dayjs.utc();
	if (offset && offset.value > 0) {
		date = date.add(offset.value, offset.unit);
	} else if (offset && offset.value < 0) {
		date = date.subtract(offset.value, offset.unit);
	}
	return date.local().format(format);
};

export const getDateWithAddedSecondsFromNow = (seconds: number): string =>
	dayjs().add(seconds, 'second').format(`${DateFormat.YEAR_MONTH_DAY}T${DateFormat.DURATION_UI}`);

export const getDifferenceBetweenDatesInSeconds = (startDate: string, endDate: string) => {
	const dayJsEndDate = dayjs(endDate);
	return dayJsEndDate.diff(startDate, 'second');
};

export const isWithinTimeInterval = (date: string | undefined, timeIntervalInMilliseconds: number): boolean =>
	dayjs.utc(date).isBefore(dayjs.utc().add(timeIntervalInMilliseconds, 'ms'));

export const isBeforeCurrentTime = (date: string): boolean => dayjs.utc().isBefore(dayjs.utc(date), 'minute');

export const isAfterMinutesInPast = (date: string, minutes: number) => {
	return dayjs.utc(date).isAfter(dayjs.utc().subtract(minutes, 'minute'));
};

export const isFutureUtcDate = (date: string) => {
	return dayjs.utc(date).isAfter(dayjs.utc(), 'minute');
};

export const convertToSeconds = (valueInMilliseconds: number): number => valueInMilliseconds / 1000;

export const getBroadcastDuration = (startDate?: string, stopDate?: string) => {
	if (!startDate || !stopDate) return 0;
	return dayjs(stopDate).diff(dayjs(startDate), 'second');
};

export const isInPastDays = (utcDateTime: string, days: number) => {
	return dayjs.utc().diff(dayjs.utc(utcDateTime), 'day') < days;
};
