import { useEffect } from 'react';
import * as serviceWorker from '../serviceWorker';
import { restartApp } from '../domain/app/appManager';
import RoutePaths from '../navigation/routePaths';
import { useHistory } from 'react-router-dom';
import { sessionStorageEntry } from '@content-technology-partners-ltd/shared-util';

export const FORCE_RELOAD_KEY = 'shouldForceReload';
const forceReload = sessionStorageEntry<string | null>(FORCE_RELOAD_KEY, null);

const useAutoUpdate = () => {
	const history = useHistory();

	const handleAutoUpdate = () => {
		if (forceReload.get()) {
			const isReloadAllowed = !history.location.pathname.includes(RoutePaths.WatchVideo);

			if (isReloadAllowed) {
				forceReload.clear();
				restartApp();
			}
		} else {
			serviceWorker.onSWLoaded();
		}
	};

	useEffect(() => {
		return history.listen(handleAutoUpdate);
	}, [history]);
};

export default useAutoUpdate;
